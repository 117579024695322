import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js/auto";
import styles from "./BurnDownChart.module.scss";
import expandIcon from "../../../assets/svg/expand.svg";
import dateIcon from "../../../assets/svg/DateRange.svg";
import unionIcon from "../../../assets/svg/graphUnion.svg";
import groupIcon from "../../../assets/svg/Group 20.svg";
import BurnDownChartModal from "./BurnDownChartModal";
import { getApiCall } from "../../../utils/Utils";
import {
  burndownChartWarning,
  failureResponses,
  monthNames,
} from "../../../constants/constants";
import { useNavigate } from "react-router";
import { Box, Tooltip as MuiTooltip } from "@mui/material";
import BurnDownChartError from "../../../assets/svg/BurndownChartError.svg";
import infoIcon from "../../../assets/svg/ChartTooltipIcon.svg";
import { showBurndownChartTooltip } from "../../../utils/utilityFunctions";

ChartJS.register(LinearScale, Title, Tooltip, Legend);
const defaultChartData = {
  labels: [] as number[],
  datasets: [
    {
      label: "Story Points Left",
      data: [],
      steppedLine: true,
      tension: 0,
      borderColor: "rgba(255, 99, 132, 1)",
      pointRadius: 0,
      borderWidth: 1,
      borderDash: [5, 5],
      borderDashOffset: 0,
    },
    {
      label: "Ideal Story Points",
      data: [] as number[],
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      fill: true,
      tension: 0,
      borderColor: "transparent",
      pointRadius: 0,
    },
  ],
};

interface ActuaDataSet {
  story_point_left: number;
  tickets_left: number;
  date: string;
}

const BurnDownChart = (props: any) => {
  const navigate = useNavigate();

  const [sprintStartDate, setSprintStartDate] = useState("");
  const [sprintActualData, setSprintActualData] = useState<ActuaDataSet[]>([]);
  const [chartData, setChartData] = useState(defaultChartData);
  const [sprintDateNotAddedError, setSprintDateNotAddedError] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [open, setOpen] = useState(false);

  const handleModalCLose = () => {
    setOpen(false);
  };

  const { start_date, end_date } = props;
  const invalidDate =
    !start_date && !end_date ? "start and end" : !start_date ? "start" : "end";

  const sprintSuccessCallBack = (response: any) => {
    setSprintStartDate(response.startdate);
    setSprintActualData(response.chart_data.actual_data);
    const startDate = new Date(response.startdate);
    const endDate = new Date(response.enddate);
    const timeDifference = endDate.getTime() - startDate.getTime();

    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    const actualData = response.chart_data.actual_data;
    actualData.length > 0 &&
      setShowTooltip(
        showBurndownChartTooltip(
          actualData[0].story_point_left,
          actualData[actualData?.length - 1].story_point_left
        )
      );
    const totalStoryPoints = response.chart_data.total_story_point;
    const idealStoryPoints = [totalStoryPoints];
    for (let day = 1; day <= daysDifference; day++) {
      const idealPoint =
        totalStoryPoints - day * (totalStoryPoints / daysDifference);
      idealStoryPoints.push(idealPoint);
    }
    const labels = Array.from({ length: daysDifference + 1 }, (_, i) => i);
    const currentDate = new Date().toISOString().split("T")[0];
    const filteredChartDataArray = actualData.filter(
      (item: { date: string }) => item.date <= currentDate
    );

    const storyPoints = filteredChartDataArray.map(
      (item: any) => item.story_point_left
    );

    setChartData({
      labels,
      datasets: [
        {
          label: "Story Points Left",
          data: storyPoints,
          steppedLine: true,
          tension: 0.7,
          borderColor: "#16B1FF",
          pointRadius: 1.5,
          borderWidth: 2,
          borderDash: [5, 4],
          borderDashOffset: 0,
        },
        {
          label: "Ideal Story Points",
          data: idealStoryPoints as number[],
          backgroundColor: "#93E286",
          fill: true,
          tension: 0,
          borderColor: "transparent",
          pointRadius: 0,
        },
      ],
    });
  };

  const sprintFaileureCallBack = (response: any) => {
    setChartData(defaultChartData);
    if (response === failureResponses.SPRINT_DATE_NOT_ADDED)
      setSprintDateNotAddedError(true);
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
    else if (response === failureResponses.NO_PERMISSION)
      navigate(`/project-details/${props.projectId}`);
  };

  const getSprintData = () => {
    setSprintDateNotAddedError(false);
    getApiCall(
      `/projects/${props.projectId}/sprint/${props.sprintId}/burndown_chart/`,
      sprintSuccessCallBack,
      sprintFaileureCallBack
    );
  };

  useEffect(() => {
    props.sprintId !== -1 && getSprintData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sprintId]);

  const getOrCreateTooltip = (chart: any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transition = "all .1s ease";

      const table = document.createElement("table");
      table.style.margin = "0px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    return tooltipEl;
  };

  const externalTooltipHandler = (context: any) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    const startDate = new Date(sprintStartDate);
    const millisecondsToAdd =
      tooltip.dataPoints &&
      tooltip?.dataPoints[0]?.parsed.x * 24 * 60 * 60 * 1000;
    const newDate = new Date(startDate.getTime() + millisecondsToAdd);
    const day = newDate.getDate();
    const month = monthNames[newDate.getMonth()];
    const year = newDate.getFullYear();
    const formattedDate = `${day} ${month}, ${year}`;

    const ticketLeft =
      tooltip.dataPoints &&
      sprintActualData[tooltip?.dataPoints[0]?.parsed.x].tickets_left;

    const tooltipModel = context.tooltip;
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    if (tooltip.body) {
      const tableBody = document.createElement("tbody");
      tableBody.className = styles.tooltipContainer;

      const story = document.createElement("div");
      story.className = styles.storyDiv;
      const storyImgDiv = document.createElement("div");
      storyImgDiv.className = styles.dateImgDiv;
      const storyImg = document.createElement("img");
      storyImg.src = groupIcon;
      storyImg.alt = "SP";
      const storyText = document.createElement("p");
      storyText.innerText =
        tooltip.dataPoints &&
        `${tooltip?.dataPoints[0]?.parsed.y} Story Points`;
      storyText.className = styles.storyText;
      storyImgDiv.appendChild(storyImg);
      story.appendChild(storyImgDiv);
      story.appendChild(storyText);

      const date = document.createElement("div");
      date.className = styles.storyDiv;
      const dateText = document.createElement("p");
      const dateImgDiv = document.createElement("div");
      dateImgDiv.className = styles.dateImgDiv;
      const dateImg = document.createElement("img");
      dateImg.src = dateIcon;
      dateImg.alt = "DDMMYY";
      dateText.className = styles.storyText;
      dateText.innerText = formattedDate;
      dateImgDiv.appendChild(dateImg);
      date.appendChild(dateImgDiv);
      date.appendChild(dateText);

      const ticket = document.createElement("div");
      ticket.className = styles.storyDiv;
      const ticketText = document.createElement("p");
      const ticketImgDiv = document.createElement("div");
      ticketImgDiv.className = styles.dateImgDiv;
      const ticketImg = document.createElement("img");
      ticketImg.src = unionIcon;
      ticketImg.alt = "Ticket";
      ticketText.innerText = `${ticketLeft} tickets`;
      ticketText.className = styles.storyText;
      ticketImgDiv.appendChild(ticketImg);
      ticket.appendChild(ticketImgDiv);
      ticket.appendChild(ticketText);

      tableBody.appendChild(story);
      tableBody.appendChild(date);
      tableBody.appendChild(ticket);
      const tableRoot = tooltipEl.querySelector("table");

      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";

    const tooltipWidth = tooltipEl.offsetWidth;
    const tooltipHeight = tooltipEl.offsetHeight;

    const offsetX = tooltipModel.caretX;
    const offsetY = tooltipModel.caretY;
    const position = context.chart.canvas.getBoundingClientRect();
    let left = position.left + window.scrollX + offsetX;
    let top = position.top + window.scrollY + offsetY;

    const modal = context.chart.canvas.closest(".MuiBox-root");

    if (modal && open) {
      const modalRect = modal.getBoundingClientRect();
      const canvasRect = chart.canvas.getBoundingClientRect();
      left = canvasRect.left - modalRect.left + offsetX;
      top = canvasRect.top - modalRect.top + offsetY;
    }

    if (left + tooltipWidth > window.innerWidth) left -= tooltipWidth;
    if (top + tooltipHeight > window.innerHeight) top -= tooltipHeight;

    tooltipEl.style.left = left + "px";
    tooltipEl.style.top = top + "px";
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        min: 0,
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className={styles.chartBox}>
      <div className={styles.headingContainer}>
        <Box className={styles.burndownChartHeader}>
          <p className={styles.heading}>Burndown Chart</p>
          {showTooltip && (
            <MuiTooltip title={burndownChartWarning} placement="top-start">
              <img src={infoIcon} alt="tooltip shown for flat graph lines" />
            </MuiTooltip>
          )}
        </Box>
        <div className={styles.rightHeader}>
          <div className={styles.labelContainer}>
            <div className={styles.idealLabelContainer}>
              <div className={styles.idealLabelIcon}></div>
              <p className={styles.labelText}>Ideal</p>
            </div>
            <div className={styles.actualLabelContainer}>
              <div className={styles.actualLabelIcon}></div>
              <p className={styles.labelText}>Actual</p>
            </div>
          </div>
          <div className={styles.expandIconContainer}>
            <img
              src={expandIcon}
              alt="expand"
              onClick={() => !sprintDateNotAddedError && setOpen(true)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
      {!sprintDateNotAddedError ? (
        <div className={styles.chartContainer}>
          <div className={styles.subContainer}>
            <p className={styles.verticalText}>Story Points</p>
            <div className={styles.chart}>
              <Line
                className={styles.chartStyles}
                data={chartData}
                options={chartOptions}
              />
            </div>
          </div>
          <div className={styles.horizontalTextContainer}>
            <p className={styles.horizontalText}>Days</p>
          </div>
        </div>
      ) : (
        <Box className={styles.BurndownChartError}>
          <img
            src={BurnDownChartError}
            alt="Burndown chart returns 400 due to missing sprint date"
          />
          <p className={styles.errorText}>
            To view the data, please add the {invalidDate} date for this Sprint
            in Gitlab
          </p>
        </Box>
      )}
      <BurnDownChartModal
        open={open}
        handleClose={handleModalCLose}
        chartData={chartData}
        chartOptions={chartOptions}
        showModalTooltip={showTooltip}
      />
    </div>
  );
};

export default BurnDownChart;

import {
  Box,
  Button,
  Grid, Modal, Paper,
  styled,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
}
  from "@mui/material";
import stylesMember from "../Members/Members.module.scss";
import React, { useContext } from "react";
import ProjectContext from "../Helper/ProjectContext";
import styles from "../PointingPoker/PointingPoker.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getApiCall, postApiCall } from "../../utils/Utils";
import { toast, ToastContainer } from "react-toastify";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import backArrow from "../../assets/svg/backArrow.svg"
import InfiniteScroll from "react-infinite-scroll-component";
import { failureResponses } from "../../constants/constants";
import HeaderSearch from "../Header/HeaderSearch";
import { useDebounce } from 'use-debounce';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: '#fff',
  borderRadius: '6px',
  boxShadow: 24,
  padding: 2,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(58, 53, 65, 0.04)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PointingPoker = (props: any) => {

  interface SessionList {
    id: number,
    name: string,
    created_at: string,
    active: boolean,
    total_story_point: number,
    story_count: number
  }

  const [sessionSearch, setSessionSearch] = React.useState('');
  const debounce = useDebounce(sessionSearch?.length > 2 ? sessionSearch : '', 500);

  const userProjectRole = useContext(ProjectContext);
  const userRole = userProjectRole.role === 'MEMBER' ? false : true;

  const navigate = useNavigate();
  const pageRef = React.useRef(1);
  const [loader, setLoader] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const params = useParams();
  const projectId = params.projectId;

  const [sessions, setSessions] = React.useState<SessionList[]>([]);
  const [hasMore, setHasMore] = React.useState(true)

  const [sessionName, setSessionName] = React.useState('');
  const [description, setDescription] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const [nameError, setNameError] = React.useState(false);
  const [backendNameError, setBackendNameError] = React.useState('');
  const [descriptionError, setDescriptionError] = React.useState('')
  const [createSession, setCreateSession] = React.useState(false)
  const detailsSuccessCallBack = (response: any) => {
    props.setRole(response.role)
  }
  const detailsFailureCallBackFunction = (response:any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
    else toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  React.useEffect(() => {
    props.setShowSidebar(true);
  }, [props])

  React.useEffect(() => {
    if (userProjectRole.role === "") {
      getApiCall(
        `/projects/${projectId}/details/`,
        detailsSuccessCallBack,
        detailsFailureCallBackFunction
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSessionList = async (search: boolean) => {
    const pageSize = rowsPerPage;
    const pageNo = pageRef.current;
    search && await setSessions([]);

    getApiCall(`/projects/${projectId}/sessions/?page_size=${pageSize}&page=${pageNo}&search=${debounce[0]}`, successCallBackFunction, failureCallBackFunction);
    setLoader(true);
  }

  const successCallBackFunction = (response: any) => {
    const pageCount = Math.ceil(response.count / 100);
    if (pageRef.current <= pageCount) {
      if (pageRef.current < pageCount) {
        setHasMore(true)
        pageRef.current = pageRef.current + 1;
      } else setHasMore(false);
      setSessions(prev => ([...prev, ...response.results]));
    }
    setLoader(false);
  }

  const failureCallBackFunction = (response: any) => {
    setLoader(false);
    navigate('/');
  }

  const handleSubmit = () => {
    if (sessionName === "") {
      setNameError(sessionName === "");
    }
    else {
      let sessionData = {
        name: sessionName,
        description: description,
      };
      postApiCall(
        `/projects/${projectId}/sessions/`,
        sessionData,
        saveSuccessCallBackFunction,
        saveFailureCallBackFunction
      );
      setLoader(true);
    }
  }
  React.useEffect(() => {
    if (createSession) getSessionList(false);
    setCreateSession(false)

  }, [pageRef.current, createSession])

  const saveSuccessCallBackFunction = (response: any) => {
    setCreateSession(true)
    pageRef.current = 1;
    setLoader(false);
    toast.success("The session has successfully been created", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setSessions([])
    resetForm();
  };

  const saveFailureCallBackFunction = (
    errorResponse: any
  ) => {
    setLoader(false);
    if (errorResponse.detail === "This session name is already taken, please select another name") {
      setBackendNameError(errorResponse.detail)
    }
    if(errorResponse.name) {
      setBackendNameError(errorResponse.name)
    }
    if(errorResponse.description) {
      setDescriptionError(errorResponse.description)
    }
    else if(errorResponse === failureResponses.INVALID_TOKEN) navigate('/')
    else if(!errorResponse.detail && !errorResponse.name && !errorResponse.description) {
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      resetForm();
    }
  };

  const resetForm = () => {
    setDescriptionError('')
    setBackendNameError('')
    setNameError(false)
    setSessionName('');
    setDescription('');
    handleModalClose();
  };

  const convertDateTime = (datetime: Date) => {
    let formattedDate = new Date(datetime).toLocaleString('en-us', { month: 'long', year: 'numeric', day: 'numeric' })
    let formattedTime = new Date(datetime).toLocaleString('en-us', { hour: 'numeric', minute: 'numeric', second: 'numeric' })
    return <React.Fragment>{formattedDate}<br />{formattedTime}</React.Fragment>;
  }

  React.useEffect(()=>{
    pageRef.current = 1;
    getSessionList(true);
  },[debounce[0]])

  return (
    <>
      {loader && <LazyLoader />}
      <ToastContainer />
      <Grid item md={10}>
        <Box className={stylesMember.headingBlock}>
          <div className={stylesMember.header}>
            <Link to={`/project-details/${projectId}/`}>
              <img src={backArrow} alt="back" />
            </Link>
            <Typography className={stylesMember.headingLabel}>Poker Sessions {sessions.length > 0 && `(${sessions.length})`}</Typography>
          </div>
          <Grid item md={4} className={stylesMember.addButton}>
            <HeaderSearch 
              sessionSearch={sessionSearch}
              setSessionSearch={setSessionSearch}
              page={'sessions_listing'}
            />
            {userRole && userProjectRole.role !== "" &&
              <Button variant="outlined" className={stylesMember.buttonAdd} onClick={handleModalOpen}>
                + CREATE SESSION
              </Button>
            }
          </Grid>
        </Box>


        <Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className={styles.activityTable}
              >
                <colgroup>
                  <col style={{ width: "22%", maxWidth: "264px" }}></col>
                  <col style={{ width: "22%" }}></col>
                  <col style={{ width: "22%" }}></col>
                  <col style={{ width: "22%" }}></col>
                </colgroup>
                <TableHead className={styles.tableHead}>
                  <TableRow>
                    <TableCell
                      className={styles.tableHeadCell}
                    >
                      <Box component="span" className={styles.tableHeadTitleCell}>
                        SESSION NAME
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center" }}
                      className={styles.tableHeadCell}
                    >
                      <Box component="span" className={styles.tableHeadTitleCell}>
                        NUMBER OF STORIES
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center" }}
                      className={styles.tableHeadCell}
                    >
                      <Box component="span" className={styles.tableHeadTitleCell}>
                        TOTAL STORY POINTS
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center" }}
                      className={styles.tableHeadCell}
                    >
                      <Box component="span" className={styles.tableHeadTitleCell}>
                        CREATED
                      </Box>
                    </TableCell>
                    {userRole &&
                      <TableCell className={styles.tableHeadCell}>&nbsp;</TableCell>
                    }
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <Box id="scrollableDetailDiv" className={stylesMember.scrollContainer} >
              <InfiniteScroll
                dataLength={sessions.length}
                next={() => getSessionList(false)}
                hasMore={hasMore}
                loader={loader && <LazyLoader />}
                scrollableTarget="scrollableDetailDiv"
              >
                <Table className={styles.activityTable}>
                  <colgroup>
                    <col style={{ width: "22%", maxWidth: "264px" }}></col>
                    <col style={{ width: "22%" }}></col>
                    <col style={{ width: "22%" }}></col>
                    <col style={{ width: "22%" }}></col>
                    {userRole && <col style={{ width: "12%", minWidth: "120px" }}></col>}                  </colgroup>
                  <TableBody>
                    {sessions
                      .map((row: any) => {
                        return (
                          <StyledTableRow key={row.id}>
                            <TableCell className={styles.sessionName}
                              onClick={() => navigate(`/${projectId}/poker-session/${row.id}`, { state: { sessionName: row.name } })}
                              sx={{ cursor: "pointer" }}>
                              <span className={styles.highlightSessionName}>{row.name}</span>                            
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }} className={styles.sessionName}>
                              {row.story_count ?? 0}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }} className={styles.sessionName}>
                              {row.total_story_point ?? 0}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }} className={styles.sessionName}>
                              {convertDateTime(row.created_at)}
                            </TableCell>
                            {userRole &&
                              <TableCell>
                                <div className={styles.moreMenu}>
                                  ...
                                </div>
                              </TableCell>
                            }
                          </StyledTableRow>
                        )
                      }
                      )}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </Box>

          </Paper>
        </Box>
      </Grid>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className={styles.modalHead}>
            <Grid item md={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2" className={styles.modalTitle}>
                New Session
              </Typography>
            </Grid>
          </Box>
          <Grid item md={12} className={styles.textField}>
            <TextField
              id="outlined-basic"
              label="Session Name"
              variant="outlined"
              fullWidth
              value={sessionName}
              required
              helperText={nameError ? "Please enter the session name" : backendNameError !== '' ? backendNameError : ""}
              error={nameError || backendNameError !== ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSessionName(event.target.value);
                setBackendNameError('')
              }}
              autoComplete="off"
            />
          </Grid>
          <Grid item md={12} className={styles.textField}>
            <TextField
              id="filled-multiline-static"
              label="Description"
              multiline
              fullWidth
              helperText={descriptionError}
              error={descriptionError !== ''}
              rows={4}
              value={description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDescriptionError('')
                setDescription(event.target.value);
              }}
              autoComplete="off"
            />
          </Grid>
          <Grid container justifyContent="center" className={styles.buttons}>
            <Button variant="outlined" className={styles.cancelButton} onClick={resetForm}>Cancel</Button>
            <Button variant="contained" className={styles.submitButton} onClick={handleSubmit}>CREATE SESSION</Button>
          </Grid>
        </Box>
      </Modal>
    </>
  )

}

export default PointingPoker;
import { Box, Container, Tab, Tabs, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../Header";
import styles from "./AcivityDetail.module.scss"
import { getApiCall } from "../../../utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import createdIcon from "../../../assets/svg/created.svg"
import deletedIcon from "../../../assets/svg/deleted.svg"
import completedIcon from "../../../assets/svg/completed.svg"
import { ArrowForward } from "@mui/icons-material";
import createdTestCase from "../../../assets/svg/created (test_case).svg"
import removedIcon from "../../../assets/svg/removed.svg"
import passedIcon from "../../../assets/svg/passed.svg"
import failedIcon from "../../../assets/svg/failed.svg"
import reopenedIcon from "../../../assets/svg/reopened.svg"
import blockedIcon from "../../../assets/svg/blocked.svg"
import InfiniteScroll from "react-infinite-scroll-component";
import LazyLoader from "../../shared/LazyLoader/LazyLoader";
import ActivitySideBar from "./ActivitySideBar";
import bug from "../../../assets/svg/bug.svg";
import epic from "../../../assets/svg/epic.svg";
import defect from "../../../assets/svg/defect.svg";
import task from "../../../assets/svg/task.svg"
import gitIcon from "../../../assets/svg/gitIcon.svg";
import testimateIcon from "../../../assets/svg/testimateIcon.svg"
import noDataImage from "../../../assets/svg/No data-pana 1.svg"
import LastSyncIcon from "../../../assets/svg/LastSync.svg";
import avatar from '../../../assets/svg/Avatar.png'
import { useSelector } from "react-redux"
import defaultProfile from '../../../assets/svg/smallUserIcon.svg'
import { formatTicketDueDate, lastSyncMessage } from "../../../utils/utilityFunctions";
import styled from "@emotion/styled";
import truncate from "lodash/truncate";
import arrowRight from '../../../assets/svg/arrowRight.svg';
import arrowLeft from '../../../assets/svg/arrowLeftBlack.svg';
import arrowLeftGrey from '../../../assets/svg/arrowLeft.svg';
import arrowRightGrey from '../../../assets/svg/arrowRightGrey.svg';
import DueDateSvg from "../../../assets/svg/DueDate.svg";
import SPIconSvg from "../../../assets/svg/SPIcon.svg";
import DueDateRedSvg from "../../../assets/svg/DueDatered.svg";
import useOverflowTooltip from "../../../utils/useShowTooltip";
import { failureResponses } from "../../../constants/constants";

const CountText = (props: any) => {
  return (
    <div className={styles.cardCountSection}>
      {props.activityCount && Object.keys(props.activityCount).map((item: any, index: number) => {
        if (props.activityCount[item] > 0) {
          let style = ''
          let text = ''
          let count
          let type = ''
          if (item === 'CREATED') {
            style = styles.createdCases
            text = 'Created'
            count = props.activityCount.CREATED
            type = props.activityCount.CREATED > 1 ? 'cases' : 'case'
          }
          else if (item === 'PASSED') {
            style = styles.passedCases
            text = 'Passed'
            count = props.activityCount.PASSED
            type = props.activityCount.PASSED > 1 ? 'cases' : 'case'
          }
          else if (item === 'DELETED') {
            style = styles.removedTasks
            text = 'Deleted'
            count = props.activityCount.DELETED
            type = props.activityCount.DELETED > 1 ? 'cases' : 'case'
          }
          else if (item === 'FAILED') {
            style = styles.removedTasks
            text = 'Failed'
            count = props.activityCount.FAILED
            type = props.activityCount.FAILED > 1 ? 'cases' : 'case'
          }
          else if (item === 'BLOCKED') {
            style = styles.blockedCases
            text = 'Blocked'
            count = props.activityCount.BLOCKED
            type = props.activityCount.BLOCKED > 1 ? 'cases' : 'case'
          }
          else if (item === 'CREATE') {
            style = styles.createdTasks
            text = 'Created'
            count = props.activityCount.CREATE
            type = props.activityCount.CREATE > 1 ? 'tasks' : 'task'
          }
          else if (item === 'COMPLETE') {
            style = styles.completedTasks
            text = 'Completed'
            count = props.activityCount.COMPLETE
            type = props.activityCount.COMPLETE > 1 ? 'tasks' : 'task'
          }
          else if (item === 'DELETE') {
            style = styles.removedTasks
            text = 'Removed'
            count = props.activityCount.DELETE
            type = props.activityCount.DELETE > 1 ? 'tasks' : 'task'
          }
          else if (item === 'REOPEN') {
            style = styles.reopenedTasks
            text = 'Reopened'
            count = props.activityCount.REOPEN
            type = props.activityCount.REOPEN > 1 ? 'tasks' : 'task'
          }
          if (item !== 'SKIPPED')
            return <p className={style}>{text} {count} {type}</p>
        }
      })}
    </div>
  )
}
const ActivityLog = (props: any) => {
  const actionToIcon = {
    COMPLETE: completedIcon,
    CREATE: createdIcon,
    DELETE: removedIcon,
    REOPEN: reopenedIcon,
    CREATED: createdTestCase,
    PASSED: passedIcon,
    DELETED: deletedIcon,
    FAILED: failedIcon,
    BLOCKED: blockedIcon,
  };

  type ActionType = 'COMPLETE' | 'CREATE' | 'DELETE' | 'REOPEN' | 'CREATED' | 'PASSED' | 'DELETED' | 'FAILED' | 'BLOCKED';
  function getActionIcon(action: ActionType) {
    return actionToIcon[action] || '';
  }

  function getActionText(log: any) {
    const logType = log.type === 'task_action' ? 'tasks' : 'cases';
    const reopenedText = log.action === 'REOPEN' ? 'ed' : 'd';
    const logAction = log.type === 'task_action' ? reopenedText : '';
    const actionText = log.is_count
      ? `${log.action.charAt(0).toUpperCase() + log.action.slice(1).toLowerCase()}${logAction} ${log.title} ${logType}`
      : log.title;

    return actionText;
  };

  return (
    <div className={styles.activityLogContainer}>
      {props.activityLog && props.activityLog.filter((item: any) => item.action !== 'SKIPPED').map((log: any, index: number) => (
        <div className={styles.taskSpan} key={index}>
          <img src={getActionIcon(log.action)} alt='' />
          <p className={styles.taskName}>{getActionText(log)}</p>
        </div>
      ))}
      {props.viewAll && 
        props.activityLog.filter((item: any) => item.action !== 'SKIPPED').length !== 0 && 
          <p 
          className={styles.viewAll} 
          onClick={  
            async () => {
              await props.setScenarioid(props.scenarioId ?? "");
              props.handleDrawerOpen(props.ticketId || props.ticketId===0 ? props.ticketId : props.id);
            }
          }>
            View all
          </p>}
    </div>
  );

}
const StatusChanges = (props: any) => {
  const statusAction = (item: any) => item?.current_status !== null ? "Moved from" : "Removed";
  return (
    <div className={styles.statusContainer}>
      {props.statusChanges && props.statusChanges.map((item: any, index: number) => {
        return (
          < div key={item.action_time} className={styles.statusSubContainer}>
            <p className={styles.statusLabel}>{item?.previous_status !== null ? statusAction(item) : "Added"}</p>
            <span className={styles.status}>{item.previous_status && <p style={{ color: item.previous_status_color }} className={styles.gitlabStatus}>{item.previous_status}</p>} {item.previous_status && item.current_status && <><ArrowForward className={styles.taskArrow} /> </>} {item.current_status && <p style={{ color: item.current_status_color }} className={styles.gitlabStatus}>{item.current_status}</p>}</span>
          </div>

        )
      })}
    </div>
  )
}

const CustomTabs = styled(Tabs)({
  "& .MuiTab-root": {
    textTransform: "none",
    padding: 0,
    height: "22px",
    color: "#FFB400",
    opacity: 1,
  },

  "& .MuiTouchRipple-root": {
    height: "22px",
  },

  "& .Mui-selected": {
    color: "#A37300",
  },

  "& .MuiTabs-indicator": {
    backgroundColor: "#A37300",
    height: "1px",
  },

  "& .MuiButtonBase-root": {
    minHeight: "22px",
  },

  "& .MuiTabs-flexContainer": {
    gap: '20px'
  },
});

const TestScenarios = (props: any) => {
  const testScenarios = props.scenarios;  
  const [value, setValue] = useState(0);
  const [scenarioStartIndex, setScenarioStartIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (props.open) {
      props.setSidebarLoader(true);
      props.setScenarioid(testScenarios[scenarioStartIndex+newValue].id);
      props.setTicketId(props.ticketId);
      props.setViewAll(true);
      props.setTicketClick(false);
    }
  };

  const handleViewClick = async (direction: string) => {
    const index = direction === 'back' ? scenarioStartIndex - 4 : null;
    const newIndex = direction === 'front' ? scenarioStartIndex + 4 : index;
    
    if (newIndex !== null && testScenarios[newIndex]) {
      await setScenarioStartIndex(newIndex);
      setValue(0);
    }
  };

  const truncateAndCapitalize = (scenario: string) => {
    const capitalizedString = scenario.charAt(0).toUpperCase() + scenario.slice(1);
    return truncate(capitalizedString, { length: 30 })
  }

  return (
    <Box className={`${styles.scenarioContainorStyle} ${!props.subTicket ? styles.paddingLeft : ''}`}>
      <Box className={styles.scenarioContent}>
        <Box 
          className={`${styles.viewMore} ${testScenarios.slice(0,scenarioStartIndex).length !== 0 ? styles.pointerCursor : ''}`}
          onClick={()=>{handleViewClick('back')}}
          >
            {testScenarios.slice(0,scenarioStartIndex).length === 0 ? <img src={arrowLeftGrey} alt="left arrow"/> : <img src={arrowLeft} alt="left arrow"/>}
        </Box>
        <Box className={styles.scenarioNames}>
          <CustomTabs
            value={value}
            onChange={handleChange}
            textColor={"inherit"}
            indicatorColor={"primary"}
            className={styles.tabsStyle}
          >
            {testScenarios.slice(scenarioStartIndex,scenarioStartIndex+4).map((item: any, index: number) => (
              <Tab
                key={item.id}
                value={index}
                label={
                  <Tooltip title={item.title.length>30 && item.title} placement="bottom">
                    <p>{truncateAndCapitalize(item.title)}</p>                                    
                  </Tooltip>
                }
                defaultChecked={index === scenarioStartIndex ? true : false}
              ></Tab>
            ))}
          </CustomTabs>
        </Box>
        <Box 
          className={`${styles.viewMore} ${testScenarios.slice(scenarioStartIndex).length > 4 ? styles.pointerCursor : ''}`}
          onClick={()=>{handleViewClick('front')}}
          >
          {testScenarios.slice(scenarioStartIndex).length > 4 ? <img src={arrowRight} alt="left arrow"/> : <img src={arrowRightGrey} alt="arrow right"/>}
        </Box>
      </Box>
      <Box className={styles.scenarioBody}>
        {testScenarios[scenarioStartIndex+value]?.activity_count && <div className={styles.countContainer}>
          <CountText activityCount={testScenarios[scenarioStartIndex+value].activity_count} />
        </div>}

        {testScenarios[scenarioStartIndex+value]?.activity_list?.filter((item: any) => item.action !== 'SKIPPED').length > 0 && <div className={`${styles.taskNameContainer} ${styles.taskContainorBackgroundWhite}`}>
        <ActivityLog activityLog={testScenarios[scenarioStartIndex+value]?.activity_list} viewAll={testScenarios[scenarioStartIndex+value].view_all} id={testScenarios[scenarioStartIndex+value].id} handleDrawerOpen={props.handleDrawerOpen} ticketId={props.ticketId} setScenarioid={props.setScenarioid} scenarioId={testScenarios[scenarioStartIndex+value].id}/>        </div>}
      </Box>
    </Box>
  );
};

interface TooltipTitleProps {
  title: string;
  externalReference?: string;
  isIndependentTest: boolean;
}

const TooltipTitle: React.FC<TooltipTitleProps> = ({ title, externalReference, isIndependentTest }) => {

  const { elementRef, showTooltip } = useOverflowTooltip(title);
  return (
    <Tooltip title={showTooltip ? title : ""} placement="top-start">
      <p className={isIndependentTest ? styles.independent : styles.title} ref={elementRef}>
        {isIndependentTest ? 'Independent Test Cases' : `${externalReference} - ${title}`}
      </p>
    </Tooltip>
  );
};

const ActivityDetail = (props: any) => {

  const [lastSyncSystem, setLastSyncSystem] = useState('');
  const [loader, setLoader] = useState(true)
  const params = useParams()
  const userId = params.userId
  const projectId = params.projectId
  const fromDatePassed = useSelector((state: any) => state.dateValue.value[0]);
  const toDatePassed = useSelector((state: any) => state.dateValue.value[1]);
  const squadPassed = useSelector((state: any) => state.squadValue.value)
  const navigate = useNavigate()

  const [currentUser, setCurrentUser] = useState(userId)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const [sidebarLoader, setSidebarLoader] = useState(false);
  const [open, setOpen] = useState(false)
  const [ticketId, setTicketId] = useState("")
  const [ticketClick, setTicketClick] = useState(false)
  const [testmateRun, setTestMateRun] = useState("")
  const [gitlabRun, setGitlabRun] = useState("")
  const [hasExternalSystem, setHasExternalSystem] = useState(false)
  const [userAvatar, setUserAvatar] = useState(avatar);
  const [viewAll, setViewAll] = useState(false);
  const [scenarioId, setScenarioid] = useState("");
  const [expand, setExpand] = useState<{ tasks: boolean; testcases: boolean }>({
    tasks: true,
    testcases: false,
  });
  const handleCountExpand = (item: string) => {
    setExpand((prev) => ({
      tasks: item === 'tasks' ? !prev.tasks : prev.tasks ? false : prev.tasks,
      testcases: item === 'testcases' ? !prev.testcases : prev.testcases ? false : prev.testcases
    }))
  }
  const handleDrawerOpen = (id: any) => {
    if (ticketId !== id) {
      setTicketId(id);
      setSidebarLoader(true);
    } else ( !viewAll || !open ) && setSidebarLoader(true);
    setTicketClick(false)
    setOpen(true);
    setViewAll(true);
  };

  useEffect(() => {
    getApiCall(
      `/projects/${projectId}/details/`,
      detailsSuccessCallBack,
      detailsFailureCallBackFunction
    );
    getApiCall(
      `/projects/${projectId}/last-sync/`,
      externalSuccessCallBackFunction,
      failureCallBack
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [activityCountData, setActivityCountData] = useState({
    name: '',
    ticket: { main_tickets: null, sub_tickets: null },
    gitlab_actvities: { CREATE: null, COMPLETE: null, DELETE: null, REOPEN: null },
    testmate_actvities: { PASSED: null, CREATED: null, DELETED: null, FAILED: null, BLOCKED: null, SKIPPED: null }
  })
  const [activityData, setActivityData] = useState<any[]>([]);

  const [standupTime, setStandupTime] = useState("")
  const date = new Date();
  if (date.getDay() === 1) {
    date.setDate(date.getDate() - 3)
  }
  else {
    date.setDate(date.getDate() - 1);
  }
  date.setHours(Number(standupTime ? standupTime.slice(0, 2) : '9'));
  date.setMinutes(Number(standupTime ? standupTime.slice(3, 5) : '0'));


  const defaultToDate = new Date()
  const [dateRange, setDateRange]: any = useState([
    fromDatePassed ?? date,
    toDatePassed ?? defaultToDate,
  ]);

  const detailsSuccessCallBack = (response: any) => {
    props.setRole(response.role)
    setStandupTime(response.standup_time)
    if (response.standup_time) {
      let fromDate = dateRange[0]
      let toDate = dateRange[1]
      fromDate.setHours(response.standup_time.slice(0, 2))
      fromDate.setMinutes(response.standup_time.slice(3, 5))
      fromDate.setSeconds(0)
      setDateRange([fromDate, toDate])
    }
  }
  const detailsFailureCallBackFunction = (response: any) => {
    if (response === failureResponses.STATUS_404) navigate("/*");
    else if (response === failureResponses.INVALID_TOKEN) navigate("/");
    else toast.error("Something went wrong,please try again", {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate("/")
  };

  const handleTicketClick = (id: any) => {
    if (ticketId !== id) {
      setTicketId(id);
      setSidebarLoader(true);
    } else ( viewAll || !open ) && setSidebarLoader(true);
    setOpen(true)
    setTicketClick(true)
    setViewAll(false)
    setScenarioid('')
  }

  const handleDateChange = (fromeDate: any, toDate: any) => {
    setPage(1)
    setDateRange([fromeDate, toDate])
  }

  function formatDate(dateIst: any) {
    const date = new Date(dateIst.getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const activityDataSuccessCallBack = (response: any) => {
    const pageCount = Math.ceil(response.count / 100)
    if (page < pageCount) {
      setHasMore(true)
      setPage(page + 1)
    }
    else setHasMore(false)
    setActivityData(prev => [...prev, ...response.results])
    setLoader(false)
  };

  const activityFaileureCallBack = (response: any) => {
    toast.error("Something went wrong,please try again", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setLoader(false)
    navigate("/project-list")
  };

  const getActivityData = () => {
    let startDate;
    let endDate;

    if (dateRange.length !== 0) {
      let sDate = new Date(dateRange[0]);
      let eDate = new Date(dateRange[1]);
      eDate.setDate(eDate.getDate());

      startDate = formatDate(sDate);
      endDate = formatDate(eDate);
    } else {
      let sDate = new Date();
      startDate = formatDate(sDate);
      endDate = formatDate(new Date());
    }
    let activityDataUrl = `/projects/${projectId}/activities/list/${currentUser}/tickets/?start_date=${startDate}&end_date=${endDate}&page_size=100&page=${page}`;
    getApiCall(activityDataUrl,
      activityDataSuccessCallBack,
      activityFaileureCallBack)
    if (page === 1) {
      getApiCall(`/projects/${projectId}/activities/list/${currentUser}/summary/?start_date=${startDate}&end_date=${endDate}`,
        activityCountSuccessCallBack,
        activityCountFaileureCallBack)
    }
    setLoader(true)
  }
  useEffect(() => {
    setActivityData([])
    if (standupTime !== "") {
      getActivityData()
      setOpen(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, standupTime, currentUser])

  const activityCountSuccessCallBack = (response: any) => {
    if (response.profile_pic) setUserAvatar(response.profile_pic)
    else setUserAvatar(defaultProfile)
    setActivityCountData(response)
    setLoader(false)
  };

  const activityCountFaileureCallBack = (response: any) => {    
    setLoader(false)
    if (response === failureResponses.STATUS_404) navigate("/*");
    else if (response === failureResponses.INVALID_TOKEN)navigate("/");
    else if (response === failureResponses.NO_PERMISSION) navigate(`/project-details/${projectId}/activity`)
    else toast.error("Something went wrong,please try again", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setLoader(false)
  }

  const externalSuccessCallBackFunction = (response: any) => {
    // function to set tooltip message and external system date 
    lastSyncMessage(response.GITLAB, response.TEST_MATE, setGitlabRun, setTestMateRun, setLastSyncSystem, setHasExternalSystem);
  };

  const failureCallBack = (response: any) => {
    navigate("/");
  }

  const itemTypeToIcon = {
    'bug': bug,
    'epic': epic,
    'story defect': defect,
    'task': task,
  };

  function dueDateColor(dateStr: string) {
    const inputDate: Date = new Date(dateStr);
    const currentDate: Date = new Date();
    inputDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    return inputDate < currentDate;   
  };
  
  return (
    <div className={styles.wraper}>
      <ToastContainer />
      <Header page="ActicityDetail"
        standupTime={standupTime}
        handleDateChange={handleDateChange}
        userName={activityCountData.name}
        dateRange={dateRange}
        userRole={props.role}
        projectId={projectId}
        currentUserId={currentUser}
        setCurrentUser={setCurrentUser}
        formatDate={formatDate}
        userAvatar={userAvatar}
        setPage={setPage}
        squadName={squadPassed}
      />
      <Container className={styles.containerBg}>
        {hasExternalSystem && (
          <div className={styles.headerContainer}>
            <div></div>
            <div className={styles.lastSyncContainer}>
              <p className={`${styles.lastSyncInline} ${lastSyncSystem && styles.lastSyncInlineRed}`}>
              {(lastSyncSystem) && 
                <Tooltip title={lastSyncSystem} placement="top">
                  <img className={styles.syncIcon} src={LastSyncIcon} alt="last sync icon"/>
                </Tooltip>
                } 
                Last Sync:
              </p>
              {gitlabRun !== "" && (
                <>
                  <img src={gitIcon} alt="gitlab" className={styles.externalIcon}></img>
                  <p className={styles.lastSyncInline}>{gitlabRun}</p>
                </>
              )}
              {testmateRun !== "" && (
                <>
                  <img src={testimateIcon} alt="testmate" className={styles.externalIcon}></img>
                  <p className={styles.lastSyncInline}>{testmateRun}</p>
                </>
              )}
            </div>
          </div>

        )}
        <div className={styles.countHeaderContainer}>
          <Box className={styles.ticketBoxContainer}>
            <div className={styles.ticketHeading}>
              <p>Tickets</p>
            </div>
            <div className={styles.ticketCountContainer}>
              <div className={styles.ticketBox}>
                <p className={styles.mainTicketCount}>{activityCountData?.ticket?.main_tickets}</p>
                <p className={styles.countText}>Main Tickets</p>
              </div>
              <div className={styles.ticketBox}>
                <p className={styles.subTicketCount}>{activityCountData?.ticket?.sub_tickets}</p>
                <p className={styles.countText}>Sub-Tickets</p>
              </div>
            </div>
          </Box>

          {Object.keys(activityCountData?.gitlab_actvities).length > 0 ?
            <Box className={styles.ticketBoxContainer}>
              <div className={styles.ticketHeading}>
                <p>Tasks</p>
                <img 
                  src={expand.tasks ? arrowLeft : arrowRight} 
                  onClick={() => handleCountExpand('tasks')}
                  className={styles.pointerCursor}/>
              </div>
              <div className={styles.ticketCountContainer}>
                {Object.keys(activityCountData?.gitlab_actvities).slice(0, expand.tasks ? undefined : 2).map((item: any, index: number) => {
                  let style = ''
                  let text = ''
                  let count

                  if (item === 'CREATE') {
                    count = activityCountData?.gitlab_actvities?.CREATE
                    style = styles.mainTicketCount
                    text = 'Created'
                  }
                  else if (item === 'COMPLETE') {
                    count = activityCountData?.gitlab_actvities?.COMPLETE
                    style = styles.subTicketCount
                    text = 'Completed'
                  }
                  else if (item === 'DELETE') {
                    count = activityCountData?.gitlab_actvities?.DELETE
                    style = styles.redColorCount
                    text = 'Removed'
                  }
                  else if (item === 'REOPEN') {
                    count = activityCountData?.gitlab_actvities?.REOPEN
                    style = styles.redColorCount
                    text = 'Reopened'
                  }

                  return (
                    <div className={styles.ticketBox}>
                      <p className={style}>{count}</p>
                      <p className={styles.countText}>{text}</p>
                    </div>
                  )
                })}
               
              </div>
            </Box>
            :
            null
          }
          {Object.keys(activityCountData?.testmate_actvities).length > 0 ?
            <Box className={styles.ticketBoxContainer}>
              <div className={styles.ticketHeading}>
                <p>Test Cases</p>
                <img 
                  src={expand.testcases ? arrowLeft : arrowRight} 
                  onClick={() => handleCountExpand('testcases')}
                  className={styles.pointerCursor}/>
              </div>
              <div className={styles.ticketCountContainer}>
                {Object.keys(activityCountData?.testmate_actvities).slice(0, expand.testcases ? undefined : 2).map((item: any, index: number) => {
                  let count
                  let text = ''
                  let style = ''
                  if (item === 'CREATED') {
                    count = activityCountData?.testmate_actvities?.CREATED
                    text = 'Created'
                    style = styles.mainTicketCount
                  }
                  else if (item === 'PASSED') {
                    count = activityCountData?.testmate_actvities?.PASSED
                    text = 'Passed'
                    style = styles.subTicketCount
                  }
                  else if (item === 'DELETED') {
                    count = activityCountData?.testmate_actvities?.DELETED
                    text = 'Deleted'
                    style = styles.redColorCount
                  }
                  else if (item === 'FAILED') {
                    count = activityCountData?.testmate_actvities?.FAILED
                    text = 'Failed'
                    style = styles.redColorCount
                  }
                  else if (item === 'BLOCKED') {
                    count = activityCountData?.testmate_actvities?.BLOCKED
                    text = 'Blocked'
                    style = styles.yellowColorCount
                  }
                  if (item !== 'SKIPPED') {
                    return (
                      <div className={styles.ticketBox}>
                        <p className={style}>{count}</p>
                        <p className={styles.countText}>{text}</p>
                      </div>
                    )
                  }
                })}
              </div>
            </Box>
            :
            null
          }
        </div>
        <Box id="scrollableDetailDiv" className={activityData.length !== 0 ? styles.scrollContainer : ""} >
          <InfiniteScroll
            dataLength={activityData.length}
            next={() => getActivityData()}
            hasMore={hasMore}
            loader={loader && <LazyLoader />}
            scrollableTarget="scrollableDetailDiv"
          >
            <Box className={styles.cardContainer}>
              {activityData && activityData.map((item: any, index: number) => {
                return (
                  <div className={styles.activityCardContainer} key={item.id}>
                    <div className={styles.cardHeader} onClick={() => item.title !== "Independent Test Cases" && handleTicketClick(item.id)}>
                      {item.type && item.type!== "user story" &&
                        <Tooltip title={item.type.charAt(0).toUpperCase() + item.type.slice(1).toLowerCase()} placement="bottom-end">
                          <img className={styles.ticketIcon} src={itemTypeToIcon[item.type as keyof typeof itemTypeToIcon]} alt='' />
                        </Tooltip>
                      }
                      <TooltipTitle
                        key={index}
                        title={item.title}
                        externalReference={item.external_reference}
                        isIndependentTest={item.title === 'Independent Test Cases'}
                      />
                      {item.story_point && (
                        <Tooltip title={`${item.story_point} Story Points`} placement="top">
                          <Box
                            onClick={(event) => event.stopPropagation()}
                            className={styles.additionalTicketDataContainer}
                          >
                            <img src={SPIconSvg} alt="story points" />
                            <span className={styles.storyPoints}>
                              {item.story_point + " SP"}
                            </span>
                          </Box>
                        </Tooltip>
                        )}
                        {item.due_date && (
                          <Tooltip title="Due date" placement="top">
                            <Box 
                              onClick={(event) => event.stopPropagation()}
                              className={styles.additionalTicketDataContainer}>
                              <img
                                src={
                                  !dueDateColor(item.due_date)
                                    ? DueDateSvg
                                    : DueDateRedSvg
                                }
                                alt="Due date of ticket"
                              />
                              <span
                                className={`${styles.dateStyles} ${
                                  dueDateColor(item.due_date) && styles.redDate
                                }`}
                              >
                                {formatTicketDueDate(item.due_date)}
                              </span>
                            </Box>
                          </Tooltip>
                        )}
                    </div>

                    { 
                    /* If test scenarios are present, then call component  */
                      item.scenarios_count>0 && 
                      <TestScenarios 
                        scenarios={item.scenarios}
                        setOpen={setOpen} 
                        handleDrawerOpen={handleDrawerOpen}
                        setScenarioid={setScenarioid}
                        ticketId={item.id}
                        setTicketId={setTicketId}
                        open={open}
                        currentTicketId={ticketId}
                        setViewAll={setViewAll}
                        setTicketClick={setTicketClick}
                        setSidebarLoader={setSidebarLoader}
                        /> 
                    }

                    {item.activity_count || item?.activity_list || item?.status_changes ? (<div className={styles.cardContentContainer}>
                      {item.activity_count && <div className={styles.countContainer}>
                        <CountText activityCount={item.activity_count} />
                      </div>}

                      {item?.activity_list?.filter((item: any) => item.action !== 'SKIPPED').length > 0 && <div className={`${styles.taskNameContainer} ${styles.taskContainorBackground}`}>

                      <ActivityLog activityLog={item?.activity_list} viewAll={item.view_all} id={item.id} handleDrawerOpen={handleDrawerOpen} scenarioId={""} setScenarioid={setScenarioid}/>                      </div>}
                      {item?.status_changes &&
                        <div className={styles.movedFrom}>
                          <StatusChanges statusChanges={item.status_changes} />

                        </div>}
                    </div>
                    )
                      : null
                    }
                    {item?.sub_tickets && item?.sub_tickets.map((subItem: any, index: number) => {
                      return (
                        <div className={styles.cardContentContainer} key={subItem.id}>
                          <div className={styles.cardHeader} onClick={() => handleTicketClick(subItem.id)}>


                            <p className={styles.title}>{`${subItem.external_reference} - ${subItem.title}`}</p>
                          </div>
                          { 
                          /* If test scenarios are present, then call component  */
                            subItem.scenarios?.length >0 && 
                            <TestScenarios 
                              scenarios={subItem.scenarios}
                              setOpen={setOpen} 
                              handleDrawerOpen={handleDrawerOpen}
                              setScenarioid={setScenarioid}
                              ticketId={subItem.id}
                              subTicket={true}
                              setTicketId={setTicketId}
                              open={open}
                              currentTicketId={ticketId}
                              setViewAll={setViewAll}
                              setTicketClick={setTicketClick}
                              setSidebarLoader={setSidebarLoader}
                              /> 
                          }

                          {subItem?.activity_count && <div className={styles.countContainer}>
                            <CountText activityCount={subItem?.activity_count} />
                          </div>}

                          {subItem?.activity_list && <div className={`${styles.taskNameContainer} ${styles.taskContainorBackgroundWhite}`}>
                            <ActivityLog activityLog={subItem?.activity_list} viewAll={subItem.view_all} id={subItem.id} handleDrawerOpen={handleDrawerOpen} scenarioId={""} setScenarioid={setScenarioid}/>
                          </div>}
                          {subItem?.status_changes &&
                            <div className={styles.movedFrom}>
                              <StatusChanges statusChanges={subItem.status_changes} />
                            </div>}
                          {index !== item.sub_tickets.length - 1 && <div className={styles.cardBorder}></div>}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
              {!loader && activityData.length === 0 &&
                <div className={styles.noDataContainer}>
                  <img src={noDataImage} />
                  <p className={styles.noData}>No data to display</p>
                </div>
              }
            </Box>
          </InfiniteScroll>
        </Box>
        {open && <ActivitySideBar page="ActivityDetail"
          open={open}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
          dateRange={dateRange}
          ticketId={ticketId}
          userId={currentUser}
          projectId={projectId}
          ticketClick={ticketClick}
          setLoader={setLoader}
          viewAll={viewAll}
          scenarioId={scenarioId}
          sidebarLoader={sidebarLoader}
          setSidebarLoader={setSidebarLoader}
        />}

      </Container>
    </div>
  )
}
export default ActivityDetail;

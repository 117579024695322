import { Autocomplete, Box, FormControl, TextField, styled } from "@mui/material";
import React, { useRef, useState } from "react";
import styles from "./Header.module.scss"
import { getApiCall } from "../../utils/Utils";
import { Link, useNavigate } from "react-router-dom";
import HeaderSearch from "../Header/HeaderSearch";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import backIcon from "../../assets/svg/arrowBack.svg";
import { toast } from "react-toastify";

const AutoComplete = styled(Autocomplete)(({ theme }) => ({
  ".MuiInputBase-input": {
    display: "flex",
    alignItems: "center",
    height: "21px",
    width: "140px",

  },
  ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRadius: "6px",
    marginTop: "-7px"
  },
  ".MuiInputBase-input-root": {
    padding: "0px"

  },
  ".MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
    paddingLeft: "0px",
    fontSize: "12px"
  }
}));


const Header = (props: any) => {
  const autocompleteRef = useRef<HTMLInputElement | null>(null);
  const [employeeList, setemployeeList] = React.useState<any[]>([]);
  const [employeeSearchValue, setEmployeeSearchValue] = useState("");
  const [nextEmployee, setNextEmployee] = React.useState<{ id: number; name: string }>({ id: -1, name: '' });
  const navigate = useNavigate()
  const [ticketList, setTicketList] = React.useState([]);
  const date = new Date();
  const [toDateValue, setToDateValue] = React.useState(props.page === "ActicityDetail" ? dayjs(props.dateRange[1]) : dayjs(date))
  if (date.getDay() === 1) {
    date.setDate(date.getDate() - 3)
  }
  else {
    date.setDate(date.getDate() - 1);
  }
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  React.useEffect(() => {
    if (props.page === 'ActicityDetail') {
      setDateValue(dayjs(props.dateRange[0]))
    }
  }, [props.dateRange])
  const [dateValue, setDateValue] = React.useState(props.page === "ActicityDetail" ? dayjs(props.dateRange[0]) : dayjs(`${year}-${month}-${day}T${!["", null].includes(props.standupTime) ? props.standupTime : '09:00'}`))
  const handleSearchChange = (event: any, option: any) => {
    if (option === null) props.setTicket("")
    if (option?.id) props.setTicket(option.ticket_no)
  }
  const handleFromDateChange = (newValue: any) => {
    setDateValue(newValue)
    if (toDateValue.isAfter(newValue)) {
      props.handleDateChange(newValue, toDateValue)
    }
    else {
      toast.error("Invalid date range, please select another date range for updated data.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  const handleToDateChange = (newValue: any) => {
    setToDateValue(newValue)
    let currentDate = new Date()
    if(newValue.isAfter(currentDate)) {
      toast.error('Future dates are not allowed for selection, please select a valid date range.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else if (newValue.isAfter(dateValue)) {
      props.handleDateChange(dateValue, newValue)
    }
    else {
      toast.error("Invalid date range, please select another date range for updated data.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  const failureCallBack = (response: any) => {
    navigate("/");
  }

  const handleBackNavigation = () => {
    if (props.userRole === "MEMBER")
      navigate(`/project-details/${props.projectId}`)
    else navigate(`/project-details/${props.projectId}/activity`)
  }

  React.useEffect(() => {
    if (props.page === "ActivityList")
      getApiCall(
        `/projects/${props.projectId}/tickets/`,
        setTicketList,
        failureCallBack
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  const userSuccessCallBackFunction = (response: any) => {
    setemployeeList(response)
  }

  const nextUserSuccessCallBackFunction = (response: any) => {
    setNextEmployee(response)
  }

  const nextButtonClick = () => {
    navigate(`/project-details/${props.projectId}/activity/${nextEmployee.id}/details`);
    props.setCurrentUser(nextEmployee.id)
    props.setPage(1)
    const ele = autocompleteRef.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
    if (ele) ele.click();
  }

  React.useEffect(() => {
    if (props.page === "ActicityDetail") {
      if (props.userRole !== "MEMBER" && props.userRole !== "") {
        getApiCall(
          `/projects/${props.projectId}/users/`,
          userSuccessCallBackFunction,
          failureCallBack
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userRole]);
  React.useEffect(() => {
    if (props.page === "ActicityDetail") {
      if (props.userRole !== "MEMBER" && props.userRole !== "") {
        let startDate;
        let endDate;
        if (props.dateRange.length !== 0) {
          let sDate = new Date(props.dateRange[0]);
          let eDate = new Date(props.dateRange[1]);
          eDate.setDate(eDate.getDate());

          startDate = props.formatDate(sDate);
          endDate = props.formatDate(eDate);
        } else {
          let sDate = new Date();
          startDate = props.formatDate(sDate);
          endDate = props.formatDate(new Date());
        }
        getApiCall(`/projects/${props.projectId}/activities/details/${props.currentUserId}/next_user/?start_date=${startDate}&end_date=${endDate}&squads=${props.squadName}`,
          nextUserSuccessCallBackFunction, failureCallBack
        )
      }
    }
    
    
  }, [props.userRole,props.dateRange, props.currentUserId])

  return (
    <Box className={styles.headerContainer}>
      {props.page === "ActicityDetail" && (
        <>
          <div className={styles.headerSectionOne}>
            <div className={styles.headingContainer}>
              <img src={backIcon} alt="back" className={styles.backIcon} onClick={handleBackNavigation} />
              <img src={props.userAvatar} alt="" className={styles.userAvatar} />
              <div>
                <p className={styles.heading}>{props.userName}</p>
              </div>
              {props.userRole !== 'MEMBER' && nextEmployee &&
                <div className={styles.nextUser} onClick={nextButtonClick}>
                  <div className={styles.nextButton}>
                    <p className={styles.nextText}>Next</p>
                  </div>
                  <p className={styles.nextUsername}>{nextEmployee.name}</p>
                </div>}
              {props.userRole !== 'MEMBER' &&
                <div>
                  <AutoComplete
                    size="small"
                    className={styles.memberListStyle}
                    getOptionLabel={(option: any) => option.name}
                    options={employeeList}
                    renderInput={(params) => <TextField {...params}
                      variant="outlined"
                      placeholder="Search"
                    />
                    }
                    renderOption={(props, option: any) => (
                      <li {...props} key={option.id}>
                        {option.name.split('.').join('​.​')}
                      </li>
                    )}
                    isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
                    onChange={(event, newValue: any) => {
                      if (newValue?.id) {
                        props.setCurrentUser(newValue.id);
                      }
                    }}
                    id="searchEmployeeField"
                    ref={autocompleteRef}
                  />

                </div>
              }
            </div>
          </div>
          <div>
          </div>
          <div className={styles.dateContainer}>
            <div className={styles.dateDiv}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="From Date"
                  slotProps={{
                    textField: { size: 'small', onBeforeInput: disableKeyboardEntry },
                    field: {
                      readOnly: true
                    }
                  }}
                  value={dateValue}
                  onAccept={(newValue) => handleFromDateChange(newValue)}
                />
              </LocalizationProvider>
            </div>
            <div className={styles.dateDiv}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="To Date"
                  slotProps={{
                    textField: { size: 'small', onBeforeInput: disableKeyboardEntry },
                    field: {
                      readOnly: true
                    }
                  }}
                  value={toDateValue}
                  onAccept={(newValue) => handleToDateChange(newValue)}
                />
              </LocalizationProvider>
            </div>
          </div>
        </>
      )}
      {
        props.page === "ActivityList" && (
          <>
            <div className={styles.headerSectionOne}>
              <div className={styles.headingContainer}>
                <Link to={`/project-details/${props.projectId}/`}>
                  <img src={backIcon} alt="back" className={styles.backIcon} />
                </Link>
                <div>
                  <p className={styles.heading}>Activities</p>
                </div>
              </div>
            </div>
            <div className={styles.headerSectionTwo}>
              <div>
                <HeaderSearch handleSearchChange={handleSearchChange} searchList={ticketList} page="activity" />
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="From Date"
                    slotProps={{
                      textField: { size: 'small', onBeforeInput: disableKeyboardEntry },
                      field: {
                        readOnly: true
                      }
                    }}
                    value={dateValue}
                    onAccept={(newValue) => handleFromDateChange(newValue)}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="To Date"
                    slotProps={{
                      textField: { size: 'small', onBeforeInput: disableKeyboardEntry },
                      field: {
                        readOnly: true
                      }
                    }}
                    value={toDateValue}
                    onAccept={(newValue) => handleToDateChange(newValue)}
                  />
                </LocalizationProvider>
              </div>
              {props.userRole &&
                <div>
                  <FormControl
                    size="small"
                    style={{ minWidth: "193px", minHeight: "36px" }}
                  >
                    <Autocomplete
                      size="small"
                      id="combo-box-demo"
                      options={props.employeeList}
                      getOptionLabel={(option: any) => option.name}
                      value={props.employee}
                      onChange={(event, newValue) => {
                        if (newValue?.id) {
                          props.setEmployee(newValue);
                        } else if (props.employee.id !== -1 && !employeeSearchValue) 
                            props.setEmployee({id:-1, name: "All"});
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={employeeSearchValue}
                          onChange={(e) => setEmployeeSearchValue(e.target.value)}
                          label="Select Employee"
                          variant="outlined"
                        />
                      )}                      
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    />
                  </FormControl>
                </div>
              }
            </div>
          </>
        )}
    </Box>
  )
}
export default Header;
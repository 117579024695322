import React, { useState } from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Tooltip,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import stylesActivity from "../ProjectActivity/ProjectActivity.module.scss";
import styles from "./StatusLabels.module.scss";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getApiCall, postApiCall, patchApiCall, deleteApiCall } from "../../utils/Utils";
import { ToastContainer, toast } from "react-toastify";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import StatusLabelModal from "./StatusLabelModal";
import backArrow from "../../assets/svg/arrowBack.svg";
import HeaderSearch from "../Header/HeaderSearch";
import InfiniteScroll from "react-infinite-scroll-component";
import { failureResponses } from "../../constants/constants";

const StatusLabels = () => {
  interface StatusList {
    id: number,
    title: string,
    color: string,
    is_default: boolean,
    created_by: string,
    role: string,
    updated_at: string
  };
  
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState(true);
  const [statusLabels, setStatusLabels] = React.useState<StatusList[]>([]);
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [open, setOpen] = React.useState(false);
  const [statusLabelData, setStatusLabelsData] = useState<{ title: string; color: string; }>({
    title: '',
    color: ''
  })
  const [fieldRequiredMessage, setFieldRequiredMessage] = useState<{ labelName: string; color: string; }>({
    labelName: '',
    color: ''
  })
  const [nameValidationError, setNameValidationError] = useState("");
  const [colorValidationError, setColorValidationError] = useState('');
  const [filterLabel, setFilterLabel] = React.useState("label");
  const [editStatusLabel, setEditStatusLabel] = React.useState(false);
  const [deleteStatusLabel, setDeleteStatusLabel] = React.useState(false);
  const [addStatusLabel, setAddStatusLabel] = React.useState(false)
  const [selectedStatusLabel, setSelectedStatusLabel] = useState<{ id: number; title: string; color: string; }>({
    id: 0,
    title: '',
    color: ''
  })
  const [countCreatedLabel, setCountCreatedLabel] = useState(0);
  const params = useParams();
  const projectId = params.projectId;

  const [searchLabel, setSearchLabel] = React.useState("");
  const [initialLoad, setInitialLoad] = React.useState<boolean>(true);
  const [hasMore, setHasMore] = React.useState(true)


  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: '#fff',
    borderRadius: '6px',
    boxShadow: 24,
    padding: 2,
  };

  const colors = ["#66FFCC", "#FFB366", "#ECB3FF", "#9999FF", "#FF9999",
    "#999966", "#FF0055", "#4D0066", "#804000", "#1F2E2E", "#D5D590",
    "#80AAFF", "#FF8566", "#66FF99", "#CCFF99", "#CC6699"]

  const handleNameRequiredMessage = (statusLabel: any) => {
    if (statusLabel.title === '')
      setFieldRequiredMessage((prevState) => ({
        ...prevState,
        labelName: "Please enter the status label name"
      }))
    else {
      setFieldRequiredMessage((prevState) => ({
        ...prevState,
        labelName: ""
      }))
    }
  }
  const handleLabelSearch = (value: any) => {
    setPage(1)
    setSearchLabel(value)
  }

  const handleStatusLabelNameValidation = (statusLabel: any) => {
    const nameLength = statusLabel.title.length;
    handleNameRequiredMessage(statusLabel);
    if (nameLength < 3)
      setNameValidationError("Should contain minimum 3 characters")
    else if (nameLength > 30)
      setNameValidationError("Maximum of 30 characters is allowed")
    else
      setNameValidationError("")
  }

  const getStatusLabelList = () => {
    const pageSize = rowsPerPage;
    const pageNo = page;
    setLoader(true);
    getApiCall(
      `/projects/${projectId}/status_labels/?page_size=${pageSize}&page=${pageNo}&label-type=${filterLabel}`,
      successCallBackFunction,
      failureCallBackFunction
    );
    setLoader(true);
  }

  const handleSaveStatusLabel = () => {
    handleNameRequiredMessage(statusLabelData);
    if (statusLabelData.color === '')
      setFieldRequiredMessage((prevState) => ({
        ...prevState,
        color: "Please select a color"
      }))
    else {
      setFieldRequiredMessage((prevState) => ({
        ...prevState,
        color: ""
      }))
    }
    if (nameValidationError === "" && statusLabelData.title !== "" &&
      statusLabelData.color !== "" && colorValidationError === "") {
      postApiCall(`/projects/${projectId}/status_labels/`, statusLabelData,
        saveSuccessCallBackFunction, failureCallBackFunction)
      setLoader(true);
    }
  }

  const handleEditStatusLabel = () => {
    handleNameRequiredMessage(statusLabelData);
    if (nameValidationError === "" && selectedStatusLabel.title !== "" &&
      selectedStatusLabel.color !== "" && colorValidationError === "") {
      patchApiCall(`/projects/${projectId}/status_label/${selectedStatusLabel.id}`,
        { title: selectedStatusLabel.title, color: selectedStatusLabel.color },
        saveSuccessCallBackFunction, failureCallBackFunction)
    }
  }

  const handleDeleteStatusLabel = () => {
    deleteApiCall(`/projects/${projectId}/status_label/${selectedStatusLabel.id}`,
      saveSuccessCallBackFunction, failureCallBackFunction)
  }

  const saveSuccessCallBackFunction = () => {
    page !== 1 && setPage(1)
    setStatusLabels([])
    if (deleteStatusLabel)
      toast.success("Status label deleted successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    else if (editStatusLabel)
      toast.success("Status label edited successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    else
      toast.success("Status label created successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    setLoader(false);
    if (page === 1) {
      handleClose()
      getStatusLabelList()
    }
  }

  const failureCallBackFunction = (response: any) => {
    setLoader(false); 
    if (response === failureResponses.INVALID_TOKEN || response === failureResponses.NO_PERMISSION || response?.detail === failureResponses.INVALID_TOKEN) {
      navigate("/")
    }
    else if (response?.title?.[0] !== null) {
      setNameValidationError(response.title[0])
    }
    else {
      toast.error("Oops! Something went wrong,please try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
      navigate("/")
    }
  };

  const successCallBackFunction = (response: any) => {
    const pageCount = Math.ceil(response.count / 100)
    if (page < pageCount) {
      setHasMore(true)
      setPage(page + 1)
    }
    else setHasMore(false)
    setTotalPage(response.count);
    if (deleteStatusLabel || editStatusLabel) setStatusLabels(response.results)
    else setStatusLabels(prev => ([...prev, ...response.results]));
    setLoader(false);
    if(!editStatusLabel && !deleteStatusLabel)
      setCountCreatedLabel(response.results.filter((item: any) => item.is_default === false).length)
    else if(deleteStatusLabel)
      setCountCreatedLabel(response.results.filter((item: any) => item.is_default === false).length)
    else if (filterLabel === "all" || filterLabel === "label")
      setCountCreatedLabel(statusLabels.length > 0 ? statusLabels.filter((item: any) => item.is_default === false).length : response.results.filter((item: any) => item.is_default === false).length);
    else
      setCountCreatedLabel(filterLabel === "created" ? 1 : 0)
  };

  const checkColorFailureCallbackFunction = (response: any) => {
    if (response.message === "color already exists ")
      setColorValidationError("The color is already taken, please select another color")
    else if (response === failureResponses.INVALID_TOKEN) {
      navigate('/');
    }
  }

  const checkColorSuccessCallbackFunction = (response: any) => {
    setColorValidationError("")
  }

  const handleAddOpen = () => {
    setAddStatusLabel(true)
    setOpen(true)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
    setStatusLabelsData({
      title: '',
      color: ''
    })
    setNameValidationError("");
    setColorValidationError("");
    setFieldRequiredMessage({
      labelName: '',
      color: ''
    })
    setEditStatusLabel(false);
    setDeleteStatusLabel(false);
    setAddStatusLabel(false)
  }

  const handleFilterLabel = (event: any) => {
    setStatusLabels([])
    setPage(1)
    setFilterLabel(event.target.value)
  }

  const handleColorSelected = (color: any) => {
    editStatusLabel ?
      setSelectedStatusLabel(
        prevState => ({
          ...prevState,
          color: color
        })
      )
      : setStatusLabelsData(
        prevState => ({
          ...prevState,
          color: color
        })
      )
    postApiCall(`/projects/${projectId}/check_status_labels/`, { color: color },
      checkColorSuccessCallbackFunction, checkColorFailureCallbackFunction)
  }

  const handleEditOpen = (statusLabel: any) => {
    setEditStatusLabel(true);
    setSelectedStatusLabel({
      id: statusLabel.id,
      title: statusLabel.title,
      color: statusLabel.color
    })
    handleOpen();
  }

  const handleDeleteOpen = (statusLabel: any) => {
    setDeleteStatusLabel(true);
    setSelectedStatusLabel({
      id: statusLabel.id,
      title: statusLabel.title,
      color: statusLabel.color
    })
    handleOpen();
  }
  const statusLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    editStatusLabel ?
      setSelectedStatusLabel(
        prevState => ({
          ...prevState,
          title: event.target.value
        })
      ) :
      setStatusLabelsData(
        prevState => ({
          ...prevState,
          title: event.target.value
        })
      )
  }

  const getStatusLabel = () => {
    const pageSize = rowsPerPage;
    const pageNo = page;
    setStatusLabels([])
    setLoader(true);
    getApiCall(
      `/projects/${projectId}/status_labels/?search=${searchLabel}&project_id=${projectId}&page_size=${pageSize}&page=${pageNo}`,
      successSearchCallBackFunction,
      failureSearchCallBackFunction
    );
  }

  const successSearchCallBackFunction = (response: any) => {
    setStatusLabels([])
    const pageCount = Math.ceil(response.count / 100)
    if (page < pageCount) {
      setHasMore(true)
      setPage(page + 1)
    }
    else setHasMore(false)
    setTotalPage(response.count);
    setStatusLabels(response.results);
    setLoader(false);
  };

  const failureSearchCallBackFunction = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
    else toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setLoader(false);
  };

  React.useEffect(() => {
    if (page === 1) {
      if (editStatusLabel || deleteStatusLabel || addStatusLabel) {
        getStatusLabelList()
        handleClose();
      }

    }
    // eslint-disable-next-line
  }, [page])
  React.useEffect(() => {
    setStatusLabels([])
    getStatusLabelList();
    setInitialLoad(false)
    // eslint-disable-next-line
  }, [rowsPerPage, projectId, filterLabel])

  React.useEffect(() => {
    if (searchLabel === '' && !initialLoad)
      getStatusLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLabel])

  return (
    <>
      {loader && <LazyLoader />}
      <Grid item md={10}>
        <ToastContainer />
        <Box className={stylesActivity.filterBlock}>
          <div style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}>
            <Link to={`/project-details/${projectId}/`}>
              <img src={backArrow} className={stylesActivity.backArrowIcon} alt="back"/>
            </Link>
            <Typography className={stylesActivity.headingLabel}>Status Labels ({totalPage})</Typography>
          </div>
          <Grid item md={12} className={stylesActivity.addButton} sx={{ display: "flex", gap: "8px" }}>
            <HeaderSearch
              page="status_label"
              onChange={(event: any) => { handleLabelSearch(event.target.value) }}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') getStatusLabel();
              }}
              onClick={getStatusLabel}
            />
            <Button
              variant="outlined"
              className={stylesActivity.buttonAdd}
              onClick={handleAddOpen}
            >
              + Add Status Label
            </Button>
          </Grid>
        </Box>
        <Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className={stylesActivity.activityTable}
              >
                {
                  countCreatedLabel > 0 ?
                    <colgroup>
                      <col style={{ width: '30%' }} />
                      <col style={{ width: '25%' }} />
                      <col style={{ width: '25%' }} />
                      <col style={{ width: '20%' }} />
                    </colgroup> :
                    <colgroup>
                      <col style={{ width: '35%' }} />
                      <col style={{ width: '35%' }} />
                      <col style={{ width: '30%' }} />
                    </colgroup>
                }
                <TableHead className={stylesActivity.tableHead}>
                  <TableRow>
                    <TableCell className={stylesActivity.tableHeadCell} sx={{ textAlign: "center" }}>
                      <Box className={stylesActivity.tableHeadTitleCell}>
                        <FormControl variant="standard" sx={{ minWidth: 80 }}>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            defaultValue="label"
                            sx={{ fontSize: '12px', fontStyle: 'normal', color: "rgba(58, 53, 65, 0.87)", fontWeight: "600" }}
                            onChange={(event) => handleFilterLabel(event)}
                          >
                            {filterLabel === "label" && (<MenuItem value="label" sx={{ display: "none" }}>Label</MenuItem>)}
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="created">Created</MenuItem>
                            <MenuItem value="default">Default</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center" }}
                      className={stylesActivity.tableHeadCell}
                    >
                      <Box
                        component="span" className={stylesActivity.tableHeadTitleCell}
                      >
                        Created By
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center" }}
                      className={stylesActivity.tableHeadCell}>
                      <Box component="span" className={stylesActivity.tableHeadTitleCell}>
                        Added On
                      </Box>
                    </TableCell>
                    {
                      countCreatedLabel > 0 &&
                      <TableCell
                        sx={{ textAlign: "center" }} className={stylesActivity.tableHeadCell}>
                        <Box
                          component="span" className={stylesActivity.tableHeadTitleCell}>
                          Actions
                        </Box>
                      </TableCell>
                    }
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <Box id="scrollableDetailDiv" className={styles.scrollContainer} >
              <InfiniteScroll
                dataLength={statusLabels.length}
                next={() => getStatusLabelList()}
                hasMore={hasMore}
                loader={loader && <LazyLoader />}
                scrollableTarget="scrollableDetailDiv"
              >
                <Table>
                  {
                    countCreatedLabel > 0 ?
                      <colgroup>
                        <col style={{ width: '30%' }} />
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '25%' }} />
                        <col style={{ width: '20%' }} />
                      </colgroup> :
                      <colgroup>
                        <col style={{ width: '35%' }} />
                        <col style={{ width: '35%' }} />
                        <col style={{ width: '30%' }} />
                      </colgroup>
                  }
                  <TableBody>
                    {statusLabels
                      .map((row: any, rowIndex: number) => {
                        return (
                          <TableRow key={row.id}
                            sx={{
                              backgroundColor: rowIndex % 2 === 0 ? "" : "#3A35410A",
                            }}>
                            <TableCell sx={{ textAlign: "center" }}>
                              <Chip label={row.title} variant="filled" size="small"
                                sx={{ backgroundColor: `${row.color}`, color: "white", fontSize: "12px" }} />
                              {row.is_default && <p className={styles.tagtext}>Default</p>}
                            </TableCell>
                            <TableCell sx={row.is_default ? { textAlign: "center", verticalAlign: "top", paddingTop: "19px" } : { textAlign: "center" }}>
                              {row.created_by}{!row.is_default && ` / ${row.role.charAt(0) + row.role.slice(1).toLowerCase()}`}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {new Date(row.updated_at).toLocaleString('en-us', { month: 'long', year: 'numeric', day: 'numeric' })}
                              <br />
                              {new Date(row.updated_at).toLocaleString('en-us', { hour: 'numeric', minute: 'numeric', second: 'numeric' })}
                            </TableCell>
                            {
                              countCreatedLabel > 0 &&
                              <TableCell sx={{ textAlign: "center" }}>
                                {!row.is_default &&
                                  <>
                                    <Box component="span">
                                      <Tooltip title="Edit" placement="bottom-end">
                                        <BorderColorIcon
                                          fontSize="medium"
                                          sx={{ paddingBottom: "3px", cursor: "pointer" }}
                                          color="action"
                                          onClick={() => handleEditOpen(row)}
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box component="span" sx={{ padding: "0 0 0 20px", cursor: "pointer" }}>
                                      <Tooltip title="Delete" placement="bottom-end">
                                        <DeleteOutlineIcon
                                          fontSize="medium"
                                          color="error"
                                          onClick={() => handleDeleteOpen(row)}
                                        />
                                      </Tooltip>
                                    </Box>
                                  </>
                                }
                              </TableCell>}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </Box>
          </Paper>
        </Box>
        <StatusLabelModal
          open={open}
          handleClose={handleClose}
          deleteStatusLabel={deleteStatusLabel}
          selectedStatusLabel={selectedStatusLabel}
          handleDeleteStatusLabel={handleDeleteStatusLabel}
          editStatusLabel={editStatusLabel}
          handleEditStatusLabel={handleEditStatusLabel}
          handleSaveStatusLabel={handleSaveStatusLabel}
          statusLabelData={statusLabelData}
          fieldRequiredMessage={fieldRequiredMessage}
          handleStatusLabelNameValidation={handleStatusLabelNameValidation}
          nameValidationError={nameValidationError}
          style={style}
          colors={colors}
          handleColorSelected={handleColorSelected}
          statusLabelChange={statusLabelChange}
          colorValidationError={colorValidationError}
        />
      </Grid>
    </>
  )
}

export default StatusLabels;

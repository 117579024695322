import React, { useEffect, useRef } from "react";
import { Autocomplete, Box, Button, CircularProgress, Container, Divider, Grid, List, ListItemButton, ListItemText, Modal, Paper, Popover, styled, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import mobileStyle from "./MobileView/DetailedPlaningPokerMobile.module.scss";
import styles from "./DetailPlanningPokerSession.module.scss";
import { getApiCall, patchApiCall, postApiCall } from "../../utils/Utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import DisplayPlayerCards from "./DisplayPlayerCards";
import ViewMembers from "../../assets/svg/viewMembers.svg";
import backArrow from "../../assets/svg/backArrow.svg";
import timerIcon from '../../assets/svg/timerIcon.svg';
import timerIconBlack from '../../assets/svg/timerIconBlack.svg';
import logo from '../../assets/svg/union.svg';
import { USER_DATA } from '../../config';
import { useDebounce } from 'use-debounce';
import checked from '../../assets/svg/CheckBox.svg';
import unChecked from '../../assets/svg/CheckBoxOutlineBlank.svg';
import truncate from 'lodash/truncate'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { failureResponses } from "../../constants/constants";
import NoStoryIcon from "../../assets/svg/NoVotingIcon.svg";
import ForceSyncIcon from "../../assets/svg/ForceSync.svg";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: '#fff',
  borderRadius: '6px',
  boxShadow: 24,
  padding: '20px',
};
const mobilePopupStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: '#fff',
  borderRadius: '6px',
  boxShadow: 24,
  padding: '24px 36px 24px 36px',
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(58, 53, 65, 0.04)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTabMobile = styled(Tabs)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'block',
  backgroundColor: 'white',
  zIndex: '1000',
  ".Mui-selected": {
    color: "#151515!important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#3A3541",
    marginBottom: '10px'
  },
  '.MuiTabs-flexContainer': {
    justifyContent: 'space-around',
    marginBottom: '10px'
  }
}));


const StyledTab = styled(Tabs)(({ theme }) => ({
  ".Mui-selected": {
    color: "#151515!important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#3A3541",
  }
}));

const CustomLoader = styled(CircularProgress)(({ theme }) => ({
  color: "#3A3541DE",
}));

const DetailPlanningPokerSession = (props: any) => {
  const polling: any = useRef();
  const [sessionName, setSessionName] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [syncLoader, setSyncLoader] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(1);
  const [members, setMembersData] = React.useState([]);
  const [userRole, setUserRole] = React.useState();
  const [storySessionFlag, setStorySessionFlag] = React.useState(false);

  const [storyId, setStoryId] = React.useState();
  const memberId = React.useRef(null)

  const [storyError, setStoryError] = React.useState('');

  const [finalStoryPoint, setFinalStoryPoint] = React.useState(0);

  const [storyStatus, setStoryStatus] = React.useState("inProgress");
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const [isPmoReveal, setIsPmoReveal] = React.useState(true);
  const [voteTimer, setVoteTimer] = React.useState(false)
  const [minutes, setMinutes] = React.useState(1)
  const [seconds, setSeconds] = React.useState(0)
  const [alert, setAlert] = React.useState(true)
  const [timerRun, setTimerRun] = React.useState(false)
  const [timerMessage, setTimerMessage] = React.useState('')
  const windowWidth = React.useRef(window.innerWidth);
  const [disableForcedSync, setDisableForcedSync] = React.useState(false);

  const [counterTime, setCounterTime] = React.useState(new Date())
  const [timerButtonShow, setTimerButtonShow] = React.useState(false)
  interface TicketListData {
    id: number,
    title: string
  }
  const [ticketList, setTicketList] = React.useState<TicketListData[]>([])
  const [searchTerm, setSearchTerm] = React.useState('')
  const debounce = useDebounce((searchTerm || '')?.length > 2 ? searchTerm : '', 500)
  const [ticketId, setTicketId] = React.useState(-1)
  const [checkBox, setCheckBox] = React.useState(true)
  const tabValueRef = useRef(1)
  // Variables to get story name, link and ID
  const [story, setStory] = React.useState<[{ id: string; title: string; link: string; }]>([{
    id: '',
    title: '',
    link: ''
  }])

  const customStyles = `
  ${(searchTerm || '').length < 3 ? '.MuiAutocomplete-noOptions { display: none; }' : ''}
`;

  React.useEffect(() => {
    if (windowWidth.current < 800) props.setShowSidebar(false);
  }, [props])

  React.useEffect(() => {
    setTicketList([]);
    if (debounce[0]?.length > 2) {
      getTicketList()
    }
    else {
      setTicketList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce[0]])

  const [completedStory, setCompletedStory] = React.useState([]);
  const userData: any = localStorage.getItem(USER_DATA)
  let profilePic: string | null = null;

  interface UserData {
    picture?: string;
  }
  try {
    const parsedData: UserData = JSON.parse(userData);
    profilePic = parsedData.picture || null;
  } catch (error) {
    console.error('Failed to parse userData:', error);
    profilePic = null;
  }

  const [open, setOpen] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };
  const getTicketList = () => {
    setSyncLoader(true);
    getApiCall(`/projects/${projectId}/ticket_names/?search=${debounce[0]}`,
      ticketListSearchSuccessCallBack,
      ticketListSearchFailureCallBack
    )
  }
  const ticketListSearchSuccessCallBack = (response: any) => {
    setSyncLoader(false);
    setTicketList(response)
  }
  const ticketListSearchFailureCallBack = (response: any) => {
    setSyncLoader(false);
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
    else toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  const handleModalOpen = () => {
    setOpen(true);
  }
  const handleModalClose = () => {
    setTicketList([])
    setOpen(false);
  }
  const statusSetter = () => {
    setStoryStatus('inProgress')
  }

  /** Get user role and user card choosen status */
  const getProjectRole = () => {
    getApiCall(
      `/projects/${projectId}/details/`,
      rolesSuccessCallBackFunction,
      ticketListSearchFailureCallBack
    )
  }

  const sessionNameSuccessCallBackFunction = (response: any) => {
    setSessionName(response.name);
  }

  const sessionNameFailureCallBackFunction = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) {
      navigate('/');
    }
  }

  // API call to get session name 
  const getSessionName = () => {
    getApiCall(
      `/projects/${projectId}/session/${sessionId}/`,
      sessionNameSuccessCallBackFunction,
      sessionNameFailureCallBackFunction
    );
  }

  const rolesSuccessCallBackFunction = (response: any) => {
    memberId.current = response.member_id
    setUserRole(response.role);
  }

  const resetForm = () => {
    setCheckBox(true)
    setStoryError('')
    handleModalClose();
  };

  /** Add story */
  const handleSubmit = () => {
    if (ticketId === -1) {
      setStoryError("Please Enter a story card or ticket number");
    }
    else {
      let storyData = {
        ticket_id: ticketId,
        post_story_points_to_ext_system: checkBox,
      };
      postApiCall(
        `/projects/${projectId}/session/${sessionId}/story`,
        storyData,
        saveSuccessCallBackFunction,
        saveFailureCallBackFunction
      );
      setLoader(true);
    }
  }

  const saveFailureCallBackFunction = (
    errorResponse: any
  ) => {
    setLoader(false);
    if (errorResponse === failureResponses.INVALID_TOKEN) navigate('/')
    else if (errorResponse.detail === failureResponses.STORY_ALREADY_CREATED) {
      toast.error(failureResponses.STORY_ALREADY_CREATED, {
        position: toast.POSITION.TOP_RIGHT,
      });
      getStory();
    }
    else if (errorResponse.title) {
      setStoryError(errorResponse.title)
    }
    else if (!errorResponse.title && !errorResponse.link) {
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      resetForm();
    }
  };

  const saveSuccessCallBackFunction = (response: any) => {
    setLoader(false);
    toast.success("The Story has successfully been created", {
      position: toast.POSITION.TOP_RIGHT,
    });
    resetForm();
    getStory();
  };


  React.useEffect(() => {
    getMembers();
    pollingCallback();
    getSessionName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    return () => {
      clearInterval(polling.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pollingCallback = () => {
    clearInterval(polling.current);
    polling.current = setInterval(() => {
      getStory();
    }, 5000);
  };

  const getMembers = () => {
    getApiCall(
      `/projects/${projectId}/pointing-poker/members/`,
      successCallBackFunction,
      failureCallBackFunction
    );
  }

  const failureCallBackFunction = (response: any) => {
    navigate("/");
  };

  const successCallBackFunction = (response: any) => {
    setMembersData(response);
    getProjectRole();
    getStory();
    getCompletedStory();
  };

  const getStory = () => {
    getApiCall(
      `/projects/${projectId}/session/${sessionId}/story?status=IN PROGRESS`,
      getStorySuccessCallBackFunction,
      getStoryFailureCallBackFunction
    );
  }

  const getStoryFailureCallBackFunction = (
    errResponse: any
  ) => {
    if (errResponse === failureResponses.INVALID_TOKEN) {
      clearInterval(polling.current)
      navigate('/')
    }
    if (errResponse === failureResponses.STATUS_404) {
      navigate('/')
    }
    setLoader(false);
    toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const getStorySuccessCallBackFunction = (response: any) => {
    if (response.length !== 0) {
      setStorySessionFlag(true);
      clearInterval(polling.current)
      resetForm();
    }
    else {
      setStorySessionFlag(false);
    }
    setStoryId(response[0].id);
    setStory(response);
    setLoader(false);
  };

  const getCompletedStory = () => {
    getApiCall(
      `/projects/${projectId}/session/${sessionId}/story?status=COMPLETED`,
      getCompletedStorySuccessCallBackFunction,
      getCompletedStoryFailureCallBackFunction
    );
  }

  const getCompletedStoryFailureCallBackFunction = (
    errResponse: any
  ) => {
    setLoader(false);
    if (errResponse === failureResponses.INVALID_TOKEN) navigate("/");
    else toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const getCompletedStorySuccessCallBackFunction = (response: any) => {
    setCompletedStory(response);
    setLoader(false);
  };

  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId;
  const sessionId = params.sessionId;

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    tabValueRef.current = newValue
    setTabValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleMemberClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /** delete story API call on click of cancel button */
  const deleteStory = () => {
    setStoryStatus("deleteStory")
  }

  const anotherRound = () => {
    setStoryStatus("anotherRound");
  }

  const completeVoting = () => {
    patchApiCall(`/projects/${projectId}/session/${sessionId}/story/${storyId}`,
      { story_point: finalStoryPoint },
      completeSuccessCallBackFunction, completeFailureCallBackFunction)

  };

  const completeFailureCallBackFunction = () => {
    navigate("/");
  };

  const completeSuccessCallBackFunction = (response: any) => {
    setStoryStatus("completed");
    toast.success("The story has successfully been completed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };


  const handleFinalStoryPoint = (finalStoryPoint: any) => {
    if (finalStoryPoint === "0") {
      finalStoryPoint = "skip";
    } else if (finalStoryPoint === "?") {
      finalStoryPoint = "unknown";
    }
    setFinalStoryPoint(finalStoryPoint);
    // additional logic
  };


  // To view member list
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : 'default-popover';

  const handleDeleteStory = () => {
    setDeleteModalOpen(true)
  }

  let timer: any;
  React.useEffect(() => {
    if (voteTimer && timerRun) {
      timer = setInterval(() => {
        let time = new Date()
        let defference = (time.getTime() - counterTime.getTime()) / 1000
        if (minutes === 0 && Math.round(seconds) === 0) {
          timeOut()
        }
        else if (Math.round(seconds) === 0 && minutes === 1) {
          setSeconds(59)
          setMinutes(0)
        }
        else if (Math.round(seconds) === -1 && minutes === 0) {
          setSeconds(0)
          timeOut()
        }
        else {
          if ((60 - defference) < 0) setSeconds(0)
          else setSeconds(60 - defference)
        }
      }, 1000)
      return () => clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voteTimer, seconds])

  const timerActivated = () => {
    setStoryStatus('Timer activated')
    setTimerRun(true)
    setVoteTimer(true)
    setAlert(true)
  }
  const anotherRoundTimer = () => {
    setTimerRun(false)
    clearInterval(timer)
  };

  const timeOut = () => {
    setTimerMessage('Time out')
    setTimerRun(false)
    clearInterval(timer)
  };

  const handleForcedSync = () => {
    setDisableForcedSync(true);
    getApiCall(
      `/project/${projectId}/sync`,
      forcedSyncSuccessCallbackFunction,
      forcedSyncFailureCallbackFunction
    );
  };

  const forcedSyncSuccessCallbackFunction = (response: any) => {};

  const forcedSyncFailureCallbackFunction = (response: any) => {
    if(response === failureResponses.INVALID_TOKEN) navigate('/')
    else toast.error("Sync failed");
  };

  const forcedSyncPollingApi = () => {
    getApiCall(
      `/project/${projectId}/sync/status`,
      forcedSyncPollingSuccessCallbackFunction,
      forcedSyncPollingFailureCallbackFunction
    );
  };

  const forcedSyncPollingSuccessCallbackFunction = (response: any) => {
    if (response.is_external_system_sync_pending === true) setDisableForcedSync(true);
    else setDisableForcedSync(false);
  }

  const forcedSyncPollingFailureCallbackFunction = (response: any) => {
    if(response === failureResponses.INVALID_TOKEN) navigate('/')
  }
  
  useEffect(() => {
    forcedSyncPollingApi();
    const intervalId = setInterval(forcedSyncPollingApi, 5000);
    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const NoStoryToVote =
    <Box className={styles.noStoryIcon}>
      <img src={NoStoryIcon} alt="no story to start voting" />
      <p className={styles.noStoryWarningTop}>There are no stories available for voting.</p>
      <p className={styles.noStoryWarningBottom}>Upon the addition of a story, you will be directed to the voting page.</p>
    </Box>

  const modalClass = () => {
    if (windowWidth.current > 800) return styles.modalStyle
    else return styles.mobileModalStyle
  }
  const checkIcon = () => {
    if(checkBox) return checked
    else return unChecked
  }
  const tooltipTitle = (name: string) => {
    if(name.length > 15) return name
    else return ''
  }
  const tabNavStyle = () => {
    if(voteTimer && storySessionFlag && alert) return mobileStyle.lowMargin
    else return mobileStyle.highMargin
  }
  const minOrSec = () => {
    if(minutes === 1) return 'Min'
    else return 'Sec'
  }
  return (
    <>
      {loader && <LazyLoader />}
      <ToastContainer />
      {windowWidth.current > 800 ?
        <>
          <Container className={styles.containerBg}>
            <Grid item md={12}>
              <Box className={styles.headerBlock}>
                <Grid container item md={9}>
                  <div style={{ display: "flex", alignItems: "center", marginLeft: "0px" }}>
                    <Link to={`/project-details/${projectId}/pointing-poker`}>
                      <img src={backArrow} alt="back" style={{ cursor: "pointer" }} />
                    </Link>
                    <Typography className={styles.sessionName}>{sessionName}</Typography>
                  </div>
                  <Button onClick={handleMemberClick}>
                    <img src={ViewMembers} alt="View members" />
                  </Button>
                  <Popover
                    id={id}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    <List
                      sx={{ width: '100%', marginRight: '40px!important', bgcolor: 'background.paper', color: 'rgba(58, 53, 65, 0.87)' }}
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                    >
                      {members.map((row: any) => {
                        return (
                          row.name !== "" &&
                          <ListItemButton key={row.id}>
                            <ListItemText primary={row.name} />
                          </ListItemButton>
                        )
                      })}
                    </List>
                  </Popover>
                </Grid>
              </Box>
            </Grid>

            <Grid item md={12}>
              <Box className={styles.tabNav}>
                <StyledTab
                  value={tabValue}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value={1} label="Current Voting" />
                  <Tab value={2} label="Completed Stories" />
                </StyledTab>
                {userRole !== "MEMBER" && !voteTimer && storySessionFlag && isPmoReveal && tabValue === 1 && timerButtonShow &&
                  <Button variant="contained"
                    className={styles.timerButton}
                    startIcon={<img src={timerIcon} alt="clock" />}
                    onClick={timerActivated}
                  >start now
                  </Button>
                }
                {voteTimer && storySessionFlag && tabValue === 1 && timerButtonShow &&
                  <div className={styles.timer}>
                    {alert && <p className={styles.ticking}>{timerMessage}</p>}
                    <div className={styles.timeContainer}>
                      <div className={styles.timeSubContainer}>
                        <img src={timerIconBlack} alt="time" />
                        <div>
                          <p className={styles.time}>{minutes}{minutes === 0 && '0'} : {Math.round(seconds) < 10 && '0'}{Math.round(seconds)} </p>
                        </div>
                        <p className={styles.time}>{minOrSec()}</p>
                      </div>
                    </div>
                  </div>
                }
              </Box>
              {tabValue === 1 && (
                <>
                  {!storySessionFlag ? (
                    <Grid container item md={12} className={styles.currentVotingBlock}>
                      <>
                        {userRole && ['OWNER', 'PM'].includes(userRole) && (
                          <Button variant="outlined" className={styles.startVotingButton} onClick={handleModalOpen}>Add Story</Button>
                        )}
                      </>
                      <>
                        {(userRole === "MEMBER" && story.length > 0) && NoStoryToVote}
                      </>
                    </Grid>
                  ) : (
                    <>
                      <Box className={styles.storyHeader}>
                        <Grid container spacing={2}>
                          <Grid item md={6}>
                            {story[0].link !== "" ? (
                              <Link to={story[0].link} target="_blank">
                                <Typography className={styles.storyName}>{story[0].title}</Typography>
                              </Link>
                            ) : (
                              <Typography className={styles.storyName}>{story[0].title}</Typography>
                            )}

                          </Grid>
                          {userRole !== "MEMBER" && !loader &&
                            <Grid container item md={6} justifyContent="right" className={styles.buttons}>
                              <Button variant="outlined" className={styles.cancelButton} onClick={handleDeleteStory}>Delete</Button>
                              <Button variant="contained" className={styles.completeButton} disabled={isPmoReveal} onClick={completeVoting}>Complete</Button>
                              <Button variant="contained" className={styles.submitButton} onClick={anotherRound}>Another Round</Button>
                            </Grid>
                          }
                        </Grid>
                        <Divider />
                      </Box>
                      <Box className={styles.playCardBox}>
                        <Grid container spacing={2}>
                          {memberId.current !== null &&
                            <DisplayPlayerCards
                              memberId={memberId.current}
                              userRole={userRole}
                              members={members}
                              projectId={projectId}
                              sessionId={sessionId}
                              storyId={story[0].id}
                              onSelectStory={handleFinalStoryPoint}
                              status={storyStatus}
                              setStatus={statusSetter}
                              setIsPmoReveal={setIsPmoReveal}
                              voteTimer={voteTimer}
                              setVoteTimer={setVoteTimer}
                              setSeconds={setSeconds}
                              setMinutes={setMinutes}
                              setAlert={setAlert}
                              setTimerRun={setTimerRun}
                              timerRun={timerRun}
                              timeOut={timeOut}
                              setTimerMessage={setTimerMessage}
                              setCounterTime={setCounterTime}
                              anotherRoundTimer={anotherRoundTimer}
                              setTimerButtonShow={setTimerButtonShow}
                              timerMessage={timerMessage}
                              tabValue={tabValueRef}
                            >
                            </DisplayPlayerCards>}
                        </Grid>
                      </Box>
                    </>
                  )}
                </>
              )
              }
              {tabValue === 2 && (
                <>
                  <Box>
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer component={Paper}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          className={styles.activityTable}
                        >
                          <TableHead className={styles.tableHead}>
                            <TableRow>
                              <TableCell
                                className={styles.tableHeadCell}
                              >
                                <Box component="span" className={styles.tableHeadTitleCell}>
                                  STORY NAME
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{ textAlign: "center" }}
                                className={styles.tableHeadCell}
                              >
                                <Box component="span" className={styles.tableHeadTitleCell}>
                                  STORY POINTS
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{ textAlign: "center" }}
                                className={styles.tableHeadCell}
                              >
                                <Box component="span" className={styles.tableHeadTitleCell}>
                                  STATUS
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {completedStory
                              .map((row: any) => {
                                return (
                                  <StyledTableRow key={row.id}>
                                    <TableCell className={styles.completedStoryName}>
                                      {row.link !== "" ? (
                                        <Link to={`${row.link}`} target="_blank">{row.title}</Link>
                                      ) : (
                                        row.title
                                      )}

                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }} className={styles.completedStoryName}>
                                      {row.story_point}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }} className={styles.completedStoryName}>
                                      COMPLETED
                                    </TableCell>
                                  </StyledTableRow>
                                )
                              }
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Box>
                </>
              )
              }
            </Grid>
          </Container>


        </>
        :
        <>
          <div className={mobileStyle.containerBg}>
            <Box className={mobileStyle.headerBlock}>
              <div >
                <img src={logo} alt="logo" className={mobileStyle.logo} />
                <p className={mobileStyle.title}>Project Tool</p>
              </div>
              <img src={profilePic || undefined} alt="profile" className={mobileStyle.profilePic} />
              <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <List
                  sx={{ width: '100%', marginRight: '40px!important', bgcolor: 'background.paper', color: 'rgba(58, 53, 65, 0.87)' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  {members.map((row: any) => {
                    return (
                      row.name !== "" &&
                      <ListItemButton key={row.id}>
                        <ListItemText primary={row.name} />
                      </ListItemButton>
                    )
                  })}
                </List>
              </Popover>
            </Box>


            {tabValue === 1 && <div className={mobileStyle.section}>
              <>
                {!storySessionFlag ? (
                  <Grid container item md={12} className={mobileStyle.currentVotingBlock}>
                    {userRole && ['OWNER', 'PM'].includes(userRole) && (
                      <Button variant="outlined" className={mobileStyle.startVotingButton} onClick={handleModalOpen}>Add Story</Button>
                    )}
                    {(userRole === "MEMBER" && story.length > 0) && NoStoryToVote}
                  </Grid>
                ) : (
                  <div className={mobileStyle.displayCardContainer}>
                    <div className={mobileStyle.nameAndMessage}>
                      <Typography className={mobileStyle.sessionName}>{sessionName}</Typography>
                      {voteTimer && storySessionFlag && alert && <p className={mobileStyle.ticking}>{timerMessage}</p>}
                    </div>

                    <Box className={`${mobileStyle.tabNav} ${tabNavStyle()}`}>
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltipOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={tooltipTitle(story[0].title)}
                          >
                            <Link to={`${story[0].link}`} target="_blank">
                              <p className={mobileStyle.storyName} onClick={handleTooltipOpen}>{truncate(story[0].title, { length: 15 })}</p>
                            </Link>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                      {userRole !== "MEMBER" && !voteTimer && storySessionFlag && isPmoReveal && tabValue === 1 && timerButtonShow &&
                        <Button variant="contained"
                          className={mobileStyle.timerButton}
                          startIcon={<img src={timerIcon} alt="clock" />}
                          onClick={timerActivated}
                        >start now
                        </Button>
                      }
                      {voteTimer && storySessionFlag && tabValue === 1 && timerButtonShow &&
                        <div className={mobileStyle.timer}>
                          <div className={mobileStyle.timeContainer}>
                            <div className={mobileStyle.timeSubContainer}>
                              <img src={timerIconBlack} alt="time" />
                              <div>
                                <p className={mobileStyle.time}>{minutes}{minutes === 0 && '0'} : {Math.round(seconds) < 10 && '0'}{Math.round(seconds)}</p>
                              </div>
                              <p className={mobileStyle.time}>{minOrSec()}</p>
                            </div>
                          </div>
                        </div>
                      }
                    </Box>
                    <Box className={mobileStyle.playCardBox}>
                      <Grid container spacing={2}>
                        {memberId.current &&
                          <DisplayPlayerCards
                            memberId={memberId.current}
                            userRole={userRole}
                            members={members}
                            projectId={projectId}
                            sessionId={sessionId}
                            storyId={story[0].id}
                            onSelectStory={handleFinalStoryPoint}
                            status={storyStatus}
                            setStatus={statusSetter}
                            setIsPmoReveal={setIsPmoReveal}
                            voteTimer={voteTimer}
                            setVoteTimer={setVoteTimer}
                            setSeconds={setSeconds}
                            setMinutes={setMinutes}
                            setAlert={setAlert}
                            setTimerRun={setTimerRun}
                            timerRun={timerRun}
                            timeOut={timeOut}
                            setTimerMessage={setTimerMessage}
                            timerActivated={timerActivated}
                            anotherRoundTimer={anotherRoundTimer}
                            setCounterTime={setCounterTime}
                            setTimerButtonShow={setTimerButtonShow}
                            timerMessage={timerMessage}
                            tabValue={tabValueRef}
                          >

                          </DisplayPlayerCards>}
                      </Grid>
                    </Box>
                  </div>
                )}
              </>
            </div>

            }
            {userRole !== "MEMBER" && storySessionFlag && tabValue === 1 &&
              <Grid container item md={6} justifyContent="right" className={mobileStyle.buttons}>
                <Button variant="outlined" className={mobileStyle.cancelButton} onClick={handleDeleteStory}>Delete</Button>
                <Button variant="contained" className={mobileStyle.completeButton} disabled={isPmoReveal} onClick={completeVoting}>Complete</Button>
                <Button variant="contained" className={mobileStyle.submitButton} onClick={anotherRound}>Another Round</Button>
              </Grid>
            }
            <StyledTabMobile
              value={tabValue}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab value={1} label="Current Voting" />
              <Tab value={2} label="Completed Stories" />
            </StyledTabMobile>

            {tabValue === 2 && (
              <div className={mobileStyle.completedStoryContainer}>
                <div className={mobileStyle.session}>
                  <p>{sessionName}</p>
                </div>
                <div className={mobileStyle.tableContainer}>
                  <div className={mobileStyle.tableHeader}>
                    <p>Title</p>
                    <p>Story point</p>
                  </div>
                  <div className={mobileStyle.tableBody}>
                    {completedStory.map((item: any, index: number) => {
                      return (
                        <div className={mobileStyle.tableRow} key={item.link}>
                          <Tooltip title={item.title} placement="top">
                            <Link to={`${item.link}`} target="_blank">
                              <p className={mobileStyle.title}>{truncate(item.title, { length: 45 })}</p>
                            </Link>
                          </Tooltip>
                          <p className={mobileStyle.storyPoint}>{item.story_point}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
            }
          </div>


        </>
      }
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item md={12} className={styles.modalHead}>
            <Typography id="modal-modal-title" variant="h6" component="h2" className={styles.modalTitle}>
              Add Story
            </Typography>
            <Box className={`${styles.forceSync} ${disableForcedSync && styles.disableForceSync}`} onClick={()=> disableForcedSync ? "" : handleForcedSync()}>
              {disableForcedSync ? <CustomLoader size="16px"/> : <img src={ForceSyncIcon} alt="force sync the latest changes from gitlab" />}
              <span className={styles.forceSyncText}>Refresh to update stories</span>
            </Box>
          </Grid>
          <Grid item md={12} className={styles.textField}>
            <div className={styles.inputFields}>
              <style>{customStyles}</style>
              <Autocomplete
                fullWidth
                options={ticketList}
                getOptionLabel={(option: any) => option.title}
                filterOptions={(item) => item}
                loading={syncLoader}
                loadingText="Loading"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Enter story name or ticket number"
                    variant="outlined"
                    onChange={(event: any) => setSearchTerm(event.target.value)}
                    helperText={storyError}
                    error={storyError !== ''}
                  />)}
                forcePopupIcon={false}
                clearOnBlur={false}
                onChange={(event, option) => {
                  if (option) setTicketId(option.id)
                  else {
                    setTicketList([])
                    setTicketId(-1)
                  }
                }}
              />
              <div className={styles.checkboxContainer}>
                <img src={checkIcon()} alt="check" onClick={() => setCheckBox(!checkBox)} />
                <p>Auto-update the story point in Gitlab</p>
              </div>
            </div>

          </Grid>
          <Grid container justifyContent="center" className={styles.buttons}>
            <Button variant="outlined" className={styles.cancelButton} onClick={resetForm}>cancel</Button>
            <Button variant="contained" className={styles.submitButton} onClick={handleSubmit}>CREATE</Button>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={windowWidth.current > 800 ? { ...style } : { ...mobilePopupStyle }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              Are you sure you want to delete the story?
            </Grid>
            <Grid item xs={12} className={styles.buttonFields}>
              <Button
                variant="outlined"
                className={styles.cancelButton}
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                variant="outlined"
                type="submit"
                className={styles.confirmButtonActive}
                onClick={deleteStory}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}

export default DetailPlanningPokerSession;
import {
  Box,
  Grid,
  Container,
  Card,
  Tooltip,
  Divider
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import styles from "./ProjectActivity.module.scss";
import { Link, useParams, useNavigate } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import created from "../../assets/svg/created.svg";
import removed from "../../assets/svg/removed.svg"
import completed from "../../assets/svg/completed.svg";
import createdTestCase from "../../assets/svg/created (test_case).svg"
import passed from "../../assets/svg/passed.svg";
import failed from "../../assets/svg/failed.svg";
import blocked from "../../assets/svg/blocked.svg";
import { getApiCall } from "../../utils/Utils";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ProjectContext from "../Helper/ProjectContext";
import LazyLoader from "../shared/LazyLoader/LazyLoader";
import reopened from '../../assets/svg/reopened.svg';
import Header from "./Header";
import InfiniteScroll from 'react-infinite-scroll-component'
import { ToastContainer, toast } from "react-toastify";
import bug from '../../assets/svg/bug.svg';
import epic from '../../assets/svg/epic.svg';
import defect from '../../assets/svg/defect.svg';
import task from '../../assets/svg/task.svg';
import gitIcon from "../../assets/svg/gitIcon.svg"
import testimateIcon from "../../assets/svg/testimateIcon.svg";
import stylesHeader from './Header.module.scss';
import LastSyncIcon from "../../assets/svg/LastSync.svg";
import {useDispatch} from "react-redux"
import {setDate} from "../../redux/store/dateReducer"
import { lastSyncMessage } from "../../utils/utilityFunctions";
import {setSquad} from '../../redux/store/squadReducer'
import { calculateTimeDifference } from "../../utils/utilityFunctions";
import SquadFilter from "./Filter/SquadFilter";
import { EXTERNAL_USER_ALERT, failureResponses } from "../../constants/constants";
import noActivitiesSvg from "../../assets/svg/noActivities.svg";
import userNotLinkedIcon from '../../assets/svg/userNotLinkedIcon.svg';
import instructionIcon from '../../assets/svg/instructionIcon.svg'

const TaskColumn = ({ taskAction, testAction }: any) => {
  return (
    <Grid item sm={5.5} md={5} lg={5}>
      <Box className={styles.tasksGrid}>
        {taskAction &&
          Object.keys(taskAction).map((task, index) => {
            if (taskAction[task] > 0) {
              let action = '';
              let classStyle = '';
              let imgSrc = '';
              if (task === "CREATE") {
                action = 'Created';
                classStyle = styles.createTask;
                imgSrc = created;
              }
              else if (task === "DELETE") {
                action = 'Removed';
                classStyle = styles.deletedTask;
                imgSrc = removed;
              }
              else if (task === "COMPLETE") {
                action = 'Completed';
                classStyle = styles.completedTask;
                imgSrc = completed;
              }
              else if (task === "REOPEN") {
                action = 'Reopened';
                classStyle = styles.reopenedTask;
                imgSrc = reopened;
              }
              return (
                <Grid item md={6} className={classStyle} key={index}>
                  <img src={imgSrc} alt="" />
                  <p>{action} {taskAction[task]} {taskAction[task] > 1 ? "tasks" : "task"}</p>
                </Grid>
              )
            }
          })}
        {testAction &&
          Object.keys(testAction).map((test, index) => {
            if (testAction[test] > 0) {
              let action = '';
              let classStyle = '';
              let imgSrc = '';
              if (test === "CREATED") {
                action = 'Created';
                classStyle = styles.createCases;
                imgSrc = createdTestCase;
              }
              else if (test === "PASSED") {
                action = 'Passed';
                classStyle = styles.passedCases;
                imgSrc = passed;
              }
              else if (test === "BLOCKED") {
                action = 'Blocked';
                classStyle = styles.blockedCases;
                imgSrc = blocked;
              }
              else if (test === "FAILED") {
                action = 'Failed';
                classStyle = styles.failedCases;
                imgSrc = failed;
              }
              else if (test === "DELETED") {
                action = 'Deleted';
                classStyle = styles.deletedTask;
                imgSrc = removed;
              }
              if (test !== "SKIPPED")
                return (
                  <Grid item md={6} className={classStyle} key={`grid${index}`}>
                    <img src={imgSrc} alt="" />
                    <p>{action} {testAction[test]} {testAction[test] > 1 ? "test cases" : "test case"}</p>
                  </Grid>
                )
            }
          })}
      </Box>
    </Grid>)
}

const TicketColumn = ({ ticket, userRole }: any) => {

  return (
    <Grid item md={4} sm={4} lg={userRole ? 3.5 : 4} className={styles.ticketGrid} key={`gridItem${ticket.id}`}>
      {ticket.type === 'bug' && <Tooltip title="Bug"><img className={styles.ticketIcon} src={bug} alt='' /></Tooltip>}
      {ticket.type === 'epic' && <Tooltip title="Epic"><img className={styles.ticketIcon} src={epic} alt='' /></Tooltip>}
      {ticket.type === 'story defect' && <Tooltip title="Story defect"><img className={styles.ticketIcon} src={defect} alt='' /></Tooltip>}
      {ticket.type === 'task' && <Tooltip title="Task"><img className={styles.ticketIcon} src={task} alt='' /></Tooltip>}
      <p className={ticket.type === null ? styles.ticketNumber : ''}>
        {
          <span>
            <Tooltip title={ticket.title} placement="bottom-end">
              <Link to={ticket.external_reference_link} target="blank">
                <span className={styles.mainTicket}>{ticket.external_reference}</span>
              </Link>
            </Tooltip>
            {ticket.sub_tickets && <span className={styles.ticketBracket}> {'('}</span>}
            {
              ticket.sub_tickets && Object.keys(ticket.sub_tickets).map((subTicketKey, index) => {
                const subTicket = ticket.sub_tickets[subTicketKey];
                return (
                  <span key={index}>
                    {(index !== 0) && <span>, </span>}
                    <Tooltip title={subTicket?.title} placement="bottom-end">
                      <Link to={subTicket?.external_reference_link} target="_blank">
                        <span key={index} className={styles.subTicket}>{subTicket?.external_reference}</span>
                      </Link>
                    </Tooltip>
                  </span>
                )
              })
            }
            {ticket.sub_tickets && <span className={styles.ticketBracket}>{')'}</span>}
          </span>
        }
      </p>
    </Grid>
  )

}

const StatusChangeColumn = ({ ticket, userRole }: any) => {
  return (
    ticket.status_change ?
      <Grid item md={3.5} sm={3.5} lg={userRole ? 3.5 : 4} className={styles.ticketContainer} key={ticket.id}>
        {ticket.status_change.previous_status !== null &&
          ticket.status_change.current_status !== null
          ? <p>
            <span className={styles.statusChange}>
              <Tooltip title={ticket.status_change.title} placement="bottom-end">
                <Link to={ticket.status_change.external_reference_link} target="_blank">
                  <span className={styles.ticketInfo}>{ticket.status_change.external_reference} </span>
                </Link>
              </Tooltip>
              <span className={styles.statusChangeLabel} style={{ color: ticket.status_change.previous_status_color }}>{ticket.status_change.previous_status}</span>
              {" "}
              <ArrowForwardIcon
                className={
                  styles.taskForwardIcon
                }
              />{" "}
              <span className={styles.statusChangeLabel} style={{ color: ticket.status_change?.current_status_color }}>{ticket.status_change.current_status}</span>
            </span>
          </p>
          : ""}
        {ticket.status_change.previous_status !== null &&
          ticket.status_change.current_status === null
          ? <p>
            <span className={styles.statusChange}>
              <Tooltip title={ticket.status_change.title} placement="bottom-end">
                <Link to={ticket.status_change.external_reference_link} target="_blank">
                  <span className={styles.ticketInfo}>{ticket.status_change.external_reference} </span>
                </Link>
              </Tooltip>
              {"Removed "}
              <span className={styles.statusChangeLabel} style={{ color: ticket.status_change?.previous_status_color }}>{ticket.status_change.previous_status}</span>
            </span>
          </p>
          : ""}
        {ticket.status_change.previous_status === null &&
          ticket.status_change.current_status !== null
          ? <p>
            <span className={styles.statusChange}>
              <Tooltip title={ticket.status_change.title} placement="bottom-end">
                <Link to={ticket.status_change.external_reference_link} target="_blank">
                  <span className={styles.ticketInfo}>{ticket.status_change.external_reference} </span>
                </Link>
              </Tooltip>
              {"Added "}
              <span className={styles.statusChangeLabel} style={{ color: ticket.status_change?.current_status_color }}>{ticket.status_change.current_status}</span>
            </span>
          </p>
          : ""}
      </Grid> : <Grid item md={3.5} sm={3.5} lg={userRole ? 3.5 : 4}></Grid>
  )
}


interface ViewAllTickets {
  [key: number]: boolean;
}

const ProjectActivity = (props: any) => {
  const dispatch = useDispatch()
  const [lastSyncSystem, setLastSyncSystem] = useState('');
  const [hasExternalSystem, setHasExternalSystem] = React.useState(false);
  const [testmateRun, setTestMateRun] = React.useState("")
  const [gitlabRun, setGitlabRun] = React.useState("")
  const userProjectRole = useContext(ProjectContext);
  const userRole = userProjectRole.role === "MEMBER" ? false : true;
  const [loader, setLoader] = React.useState(true);
  interface UserResponse { id: number, name: string }
  const [hasMore, setHasMore] = useState(true)
  const navigate = useNavigate();
  const [employeeList, setemployeeList] = useState<UserResponse[]>([]);
  const [activityData, setActivityData] = useState<any[]>([]);
  const [employee, setEmployee] = useState({ id: -1, name: "All" });
  const [ticket, setTicket] = useState("");
  const [standupTime, setStandupTime] = useState("");
  const [viewAllTickets, setViewAllTickets] = useState<ViewAllTickets>({});
  const [selectedSquad, setSelectedSquad] = React.useState<string[]>([]);
  type OptionType = ({ id: number; name: string })[];
  const [squadList, setSquadList] = React.useState<OptionType>([])

  const [selectedSquadArray, setSelectedSquadArray] = React.useState<OptionType>([]);

  const [externalUserNotLinked, setExternalUserNotLinked] = React.useState(false)

  const date = new Date();
  if (date.getDay() === 1) {
    date.setDate(date.getDate() - 3)
  }
  else {
    date.setDate(date.getDate() - 1);
  }
  date.setHours(Number(standupTime ? standupTime.slice(0, 2) : '9'));
  date.setMinutes(Number(standupTime ? standupTime.slice(3, 5) : '0'));

  const defaultToDate = new Date()
  const [defaultStartDate]: any = useState(date);
  const [dateRange, setDateRange]: any = useState([
    defaultStartDate,
    defaultToDate,
  ]);

  const [page, setPage] = useState(1);

  const params = useParams();
  const projectId = params.projectId;

  const handleDateChange = (fromDate: any, toDate: any) => {
    dispatch(setDate([fromDate.toISOString(), toDate.toISOString()]))
    setPage(1)
    setDateRange([fromDate, toDate])
  }

  const failureCallBackFunction = () => {
    setLoader(false);
    navigate("/");
  };

  const successCallBackFunction = (response: any) => {
    const pageCount = Math.ceil(response.count / 100)
    if (page < pageCount) {
      setHasMore(true)
      setPage(page + 1)
    }
    else setHasMore(false)
    setActivityData(prev => ([...prev, ...response.results]));
    setLoader(false);
  };

  const userSuccessCallBackFunction = (response: UserResponse[]) => {
    const userList = [{ id: -1, name: "All" }, ...response]
    setemployeeList(userList)
  };

  const detailsSuccessCallBack = (response: any) => {
    props.setRole(response.role)
    setActivityData([])
    setStandupTime(response.standup_time)
    if (response.standup_time === null) {
      getActivityData(false)
    }
    if (response.standup_time) {
      let fromDate = dateRange[0]
      let toDate = dateRange[1]
      fromDate.setHours(response.standup_time.slice(0, 2))
      fromDate.setMinutes(response.standup_time.slice(3, 5))
      fromDate.setSeconds(0)
      setDateRange([fromDate, toDate])
    }
  };

  const detailsFailureCallBackFunction = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate('/')
    else {
      toast.error("Something went wrong,please try again", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const squadSuccessCallBack = (response: any) => {
    if (response.length >= 1) {
      const squads = response
      if (response.length > 1) squads.unshift({ id: -1, name: 'All Squads' })
      squads.unshift({ id: -2, name: 'No Squads' })
      setSquadList(squads)
      setSelectedSquadArray([])
    }
    else {
      setSquadList(response)
      setSelectedSquadArray([])
    }
  };

  const squadFailureCallBack = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
    else toast.error('Something went wrong', { position: toast.POSITION.TOP_RIGHT })
  };

  const handleSquadChange = (event: any, option: OptionType) => {
    const removed = selectedSquadArray.filter((item: any) => !option.includes(item));
    if (removed[removed.length - 1]?.name === 'All Squads') {
      if (option.some((item: any) => item.name === 'No Squads')) {
        const updatedSquadList = squadList.filter(obj => obj.name === 'No Squads').map(obj => obj.name);
        setSelectedSquad(updatedSquadList)
        setSelectedSquadArray(squadList.filter(obj => obj.name === 'No Squads'))
      }
      else {
        setSelectedSquad([])
        setSelectedSquadArray([])
      }
    }
    else if (!option) {
      setSelectedSquad([])
      dispatch(setSquad(''))
    }
    else {
      if (option[option.length - 1]?.name === 'All Squads') {
        const updatedSquadList = squadList.filter(obj => obj.name !== 'No Squads').map(obj => obj.name);
        setSelectedSquad(updatedSquadList)
        setSelectedSquadArray(squadList.filter(obj => obj.name !== 'No Squads'))
      }
      else if (option[option.length - 1]?.name === 'No Squads') {
        if (squadList.filter((item) => !['No Squads', 'All Squads'].includes(item.name)).length !== option.filter((item: any) => !['No Squads', 'All Squads'].includes(item.name)).length) {
          const updatedSquadList = option.filter((obj: any) => obj.name !== 'All Squads').map((obj: any) => obj.name);
          setSelectedSquadArray(option.filter((obj: any) => obj.name !== 'All Squads'))
          setSelectedSquad(updatedSquadList)
        }
        else {
          const updatedSquadList = option.map((obj: any) => obj.name);
          setSelectedSquad(updatedSquadList)
          setSelectedSquadArray(option)
        }
      }
      else {
        if (squadList.filter((item) => !['No Squads', 'All Squads'].includes(item.name)).length === option.filter((item: any) => !['No Squads', 'All Squads'].includes(item.name)).length) {
          const updatedSquadList = squadList.filter((obj: any) => obj.name !== 'No Squads').map((obj: any) => obj.name);
          setSelectedSquadArray(squadList.filter((obj: any) => obj.name !== 'No Squads'))
          setSelectedSquad(updatedSquadList)
        }
        else {
          const updatedSquadList = option.filter((obj: any) => obj.name !== 'All Squads').map((obj: any) => obj.name);
          setSelectedSquadArray(option.filter((obj: any) => obj.name !== 'All Squads'))
          setSelectedSquad(updatedSquadList)
        }
      }
    }
  };

  const getUserList = () => {
    let filteredNames = squadList.filter((squad: any) => selectedSquad.includes(squad.name)).map((squad: any) => squad.name === 'No Squads' ? 'no_squad' : squad.name);
    let squadsParam;
    if (filteredNames.includes('All Squads')) {
      if (filteredNames.includes('no_squad')) squadsParam = ['all', 'no_squad']
      else squadsParam = 'all'
    }
    else squadsParam = filteredNames;

    dispatch(setSquad(squadsParam));
    getApiCall(
      `/projects/${projectId}/users/?squads=${squadsParam}`,
      userSuccessCallBackFunction,
      failureCallBackFunction
    );
  };

  useEffect(() => {
    if (userProjectRole.role !== "MEMBER" && userProjectRole.role !== "") {
      getApiCall(`/projects/${projectId}/squads/`,
        squadSuccessCallBack,
        squadFailureCallBack
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, userProjectRole.role]);

  const externalUserSuccessCallBack = (response: any) => {
    setExternalUserNotLinked(true)
  };

  useEffect(() => {
    dispatch(setDate([dateRange[0].toISOString(), dateRange[1].toISOString()]))
  }, [dateRange]);

  useEffect(() => {
    getApiCall(
      `/projects/${projectId}/details/`,
      detailsSuccessCallBack,
      detailsFailureCallBackFunction
    );
    getApiCall(
      `/projects/${projectId}/external-user-linked-status/`,
      externalUserSuccessCallBack,
      detailsFailureCallBackFunction
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialActivityData = () => {
    setViewAllTickets({})
    setPage(1)
    setActivityData([])
    if (standupTime !== "") {
      getActivityData(true);
    }
  };

  useEffect(() => {
    initialActivityData();
    getUserList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSquad]);
  
  useEffect(() => {
    initialActivityData()
    // eslint-disable-next-line
  }, [employee, ticket, dateRange, projectId]);

  function formatDate(dateIst: any) {
    const date = new Date(dateIst.getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getActivityData = (hasFilter: boolean) => {
    let startDate;
    let endDate;

    if (dateRange.length !== 0) {
      let sDate = new Date(dateRange[0]);
      let eDate = new Date(dateRange[1]);
      eDate.setDate(eDate.getDate());

      startDate = formatDate(sDate);
      endDate = formatDate(eDate);
    } else {
      let sDate = new Date();
      startDate = formatDate(sDate);
      endDate = formatDate(new Date());
    }

    let activityDataUrl = "";

    if (hasFilter) {
      let filteredNames = squadList.filter((squad: any) => selectedSquad.includes(squad.name)).map((squad: any) => squad.name === 'No Squads' ? 'no_squad' : squad.name);
      let squadsParam;
      if (filteredNames.includes('All Squads')) {
        if (filteredNames.includes('no_squad')) squadsParam = ['all', 'no_squad']
        else squadsParam = 'all'
      }
      else squadsParam = filteredNames

      activityDataUrl += `start_date=${startDate}&end_date=${endDate}&page_size=100&page=${1}&squads=${squadsParam}`;
    }
    else activityDataUrl += `start_date=${startDate}&end_date=${endDate}&page_size=100&page=${page}`;
    if (employee.id !== -1) {
      setViewAllTickets({ 0: true })
      activityDataUrl += `&user_id=${employee.id}`;
    }
    if (ticket !== "") {
      activityDataUrl += `&ticket_id=${ticket}`;
    }

    getApiCall(
      `/projects/${projectId}/activities/list/?${activityDataUrl}`,
      successCallBackFunction,
      failureCallBackFunction,
    );
    setLoader(true);
  };

  const externalSuccessCallBackFunction = (response: any) => {
    lastSyncMessage(response.GITLAB, response.TEST_MATE, setGitlabRun, setTestMateRun, setLastSyncSystem, setHasExternalSystem);
  }

  const failureCallBack = (response: any) => {
    navigate("/");
  }
  React.useEffect(() => {
    getApiCall(
      `/projects/${projectId}/last-sync/`,
      externalSuccessCallBackFunction,
      failureCallBack
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleViewAllTickets = (index: number) => {
    if (index in viewAllTickets)
      setViewAllTickets((prevViewAllTickets) => ({
        ...prevViewAllTickets,
        [index.toString()]: !(prevViewAllTickets[index]),
      }));
    else
      setViewAllTickets((prevViewAllTickets) => ({
        ...prevViewAllTickets,
        [index]: true,
      }));
  }

  return (
    <>
      <ToastContainer />
      {loader && <LazyLoader />}
      {standupTime !== "" &&
        <Header projectId={projectId}
          standupTime={standupTime}
          handleDateChange={handleDateChange}
          setTicket={setTicket}
          setEmployee={setEmployee}
          employee={employee}
          employeeList={employeeList}
          userRole={userRole}
          selectedSquad={selectedSquad}
          squadList={squadList}
          page="ActivityList" />
      }
      <Container className={styles.containerBg}>
        <div className={squadList.length > 0 ? styles.externalSystemdata : styles.alignRight}>
          {squadList.length > 0 && userRole && <SquadFilter squadList={squadList} handleSquadChange={handleSquadChange} selectedSquad={selectedSquad} selectedSquadArray={selectedSquadArray} page='activity list' />}
          {hasExternalSystem && (
            <span className={stylesHeader.lastSyncContainer}>
              <p className={`${stylesHeader.lastSyncInline} ${lastSyncSystem && stylesHeader.lastSyncInlineRed}`}>
                {(lastSyncSystem) &&
                  <Tooltip title={lastSyncSystem} placement="top">
                    <img className={stylesHeader.syncIcon} src={LastSyncIcon} alt="last sync icon" />
                  </Tooltip>
                }
                Last Sync:
              </p>
              {gitlabRun !== "" && (
                <>
                  <img src={gitIcon} alt="gitlab"></img>
                  <p className={stylesHeader.lastSyncInline}>&nbsp;{gitlabRun} </p>
                </>
              )}
              {testmateRun !== "" && (
                <>
                  <img src={testimateIcon} alt="testmate"></img>
                  <p className={stylesHeader.lastSyncInline}>&nbsp;{testmateRun}</p>
                </>
              )}
            </span>
          )}
        </div>
        <Box id="scrollableDiv" className={activityData.length !== 0 ? styles.scrollContainer : ""}>
          <InfiniteScroll
            dataLength={activityData.length}
            next={() => { getActivityData(false) }}
            hasMore={hasMore}
            loader={loader && <LazyLoader />}
            scrollableTarget="scrollableDiv"
          >
            {!loader && activityData.length !== 0 && externalUserNotLinked && squadList.length !== 0 &&
              <div className={styles.externalUserAlertDIv}>
                <img src={instructionIcon} alt="" />
                <p dangerouslySetInnerHTML={{ __html: EXTERNAL_USER_ALERT }}>
                </p>
              </div>}
            <Box className={styles.cardContainer} >
              {activityData &&
                activityData.map((row: any, index: number) => {
                  if (Object.keys(row.activity).length > 0)
                    return (
                      <Card className={styles.activityCard} key={`card_${row.id}`}>
                        <Grid container alignItems="flex-start" spacing={2} justifyContent="left" sx={{ width: "100%" }} flexDirection={"row"} key={`grid_${index}`}>
                          {
                            userRole && <Grid item md={2} sm={2} lg={2} key={`gridItem_${row.id}${userRole}`}>
                              <div className={styles.employeeNameContainer}>

                                <p className={styles.employeeName} onClick={() => navigate(`/project-details/${projectId}/activity/${row.id}/details`)} key={`employeeName_${row.id}${userRole}`}>{row.name}</p>

                                {!row.is_external_user_linked &&
                                  <Tooltip title='Unlinked user' placement="top">
                                    <img src={userNotLinkedIcon} alt="" />
                                  </Tooltip>
                                }

                              </div>
                            </Grid>
                          }
                          <Grid item md={userRole ? 10 : 12} sm={userRole ? 10 : 12} lg={userRole ? 10 : 12}>
                            {
                              row.activity.map((ticket: any, ticketIndex: number) => {
                                return (
                                  <>
                                    {ticketIndex < 3 && <Grid item sm={12} md={12} lg={12} key={ticketIndex}>
                                      {ticketIndex !== 0 && <Divider />}
                                      <Box className={styles.ticketDetails}>
                                        <TicketColumn ticket={ticket} userRole={userRole} />
                                        <StatusChangeColumn ticket={ticket} userRole={userRole} />
                                        <TaskColumn taskAction={ticket.task_action} testAction={ticket.test_action} />
                                      </Box>
                                    </Grid>}
                                    {
                                      ticketIndex >= 3 && viewAllTickets[index] && <Grid item sm={12} md={12} lg={12} key={ticketIndex}>
                                        {ticketIndex !== 0 && <Divider />}
                                        <Box className={styles.ticketDetails}>
                                          <TicketColumn ticket={ticket} userRole={userRole} />
                                          <StatusChangeColumn ticket={ticket} userRole={userRole} key={ticket.id} />
                                          <TaskColumn taskAction={ticket.task_action} testAction={ticket.test_action} />
                                        </Box>
                                      </Grid>
                                    }
                                  </>
                                )
                              })
                            }
                            {row.activity.length > 3 && <p className={styles.viewAll} onClick={() => { handleViewAllTickets(index) }}>{viewAllTickets[index] ? "View less" : "View all tickets"}</p>}
                          </Grid>
                        </Grid>
                      </Card>
                    )
                  else return null
                })
              }
              {!loader && activityData.length === 0 &&
                <div className={styles.noDataContainer}>
                  <img src={noActivitiesSvg} alt="No activities found" />
                  <p className={styles.noData}>No activities found </p>
                </div>
              }
            </Box>
          </InfiniteScroll >
        </Box>
      </Container >
    </>
  );
};

export default ProjectActivity;

import React, { useCallback } from "react";
import styles from '../AddKey/AddKey.module.scss';
import { Modal, Box, Grid, Typography, TextField, Button, Chip, Tooltip } from '@mui/material';
import ShareKey from "./ShareKey";
import stylesProjectList from "../../ProjectList/ProjectList.module.scss";
import { getApiCall, patchApiCall, postApiCall } from "../../../utils/Utils";
import * as openpgp from 'openpgp';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LinearProgressWithLabel from '../../shared/ProgressBar/ProgressBar';
import DeletedKeyIcon from "../../../assets/svg/DeletedKey.svg";
import truncate from "lodash/truncate";
import { useTooltipState } from "../tooltipUtils";
import extensionIcon from '../../../assets/svg/extensionButtonIcon.svg';
import { allowedOrigins, failureResponses, shareKeyModalValidationMessages } from "../../../constants/constants";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 705,
  bgcolor: '#fff',
  borderRadius: '6px',
  boxShadow: 24,
  padding: 2,
};

const ShareKeyModal = (props: any) => {    
  interface PublicKey {
    user: number;
    key: string;
    id: number;
  }
  const [shareWithUsers, setShareWithUsers] = React.useState<any>([]);
  const [privateKey, setPrivateKey] = React.useState("")
  const [encryptedKey, setEncryptedKey] = React.useState("")
  const [publicKeyList, setPublicKeyList] = React.useState<PublicKey[]>([]);
  const [userError, setUserError] = React.useState("")
  const [errorValidation, setErrorValidation] = React.useState<{ privateKey: string, users: string, passwordValue: string }>({ privateKey: "", users: "", passwordValue: "" })
  const params = useParams();
  const projectId = params.projectId;
  const [progress, setProgress] = React.useState(0)
  const [shareButtonClicked, setShareButtonClicked] = React.useState(false)
  const [showProgressBar, setShowProgressBar] = React.useState(false)
  const [passwordValue, setPasswordValue] = React.useState('');
  const [passwordField, setPasswordFieldShow] = React.useState(false);

  const extensionKeyRef = React.useRef<HTMLParagraphElement>(null);
  const keyMap = React.useRef<HTMLParagraphElement | null>(null);
  const [mappedKeyError, setMappedKeyError] = React.useState(false);
  const sharedUserList = React.useRef<number[]>([]);
  const shareKeyAPICount = React.useRef<number>(0);

  const navigate = useNavigate()

  const handleShareWithUsers = (value: any) => {
    setShareWithUsers(value);
    setErrorValidation((prev) => ({ ...prev, users: "" }));
  }

  const handleErrorValidation = () => {
    if (passwordValue === "") {
      setErrorValidation((prev) => ({ ...prev, passwordValue: shareKeyModalValidationMessages['keyError'] }))
    }
    else {
      setErrorValidation((prev) => ({ ...prev, passwordValue: "" }))
    }
    if (privateKey === "") {
      setErrorValidation((prev) => ({ ...prev, privateKey: shareKeyModalValidationMessages['privateKeyError'] }))
    }
    else {
      setErrorValidation((prev) => ({ ...prev, privateKey: "" }))
    }
    if (shareWithUsers.length === 0) {
      setErrorValidation((prev) => ({
        ...prev, users: shareKeyModalValidationMessages['noSelectedUsersError']
      }))
    }
    else {
      setErrorValidation((prev) => ({ ...prev, users: "" }))
    }
    if (privateKey !== "" && shareWithUsers.length !== 0) {
      return true
    }

  }

  const handleSharedUserPrivilegeChange = (event: any, userData: any) => {
    const index = shareWithUsers.findIndex((item: any) => item.id === userData.id)
    const updatedSharedUsers = [...shareWithUsers];
    updatedSharedUsers[index] = { id: userData.id, name: userData.name, privilege: event.target.value };
    setShareWithUsers(updatedSharedUsers);
  }


  const shareSuccessCallBack = (response: any) => {
    setEncryptedKey(response.password)
  }
  const shareFailureCallBack = (response: any) => {
    if(response === failureResponses.INVALID_TOKEN) navigate('/')
    else {
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    props.handleModalColse()
  }

  const trackKeySuccessCallBack = (response: any) => {
    //There is nothing to handle here for now
  };

  const trackKeyFailureCallBack = (response: any) => {
    if(response === failureResponses.INVALID_TOKEN) navigate('/')
    else toast.error('Something went wrong please try again',{position: toast.POSITION.TOP_RIGHT})
  };

  const sharePostSuccessCallBackFunction = (response: any) => {

    // track key download/share operation for each user 
    const postData = {"is_shared": true}
    patchApiCall(`/projects/${projectId}/project_key/${props.keyData.keyId}/update-status/`,
    postData,
    trackKeySuccessCallBack,
    trackKeyFailureCallBack);

    // store id of users to whom key has been shared succesfully 
    sharedUserList.current = [...sharedUserList.current, response.user];
    shareKeyAPICount.current = shareKeyAPICount.current + 1;
    if(shareKeyAPICount.current===shareWithUsers.length) {
      notifySender();
    }

    // update private key usage in extension 
    if (extensionKeyRef.current) extensionKeyRef.current.textContent = privateKey;
    let event = new CustomEvent("Update last used of private key");
    document.dispatchEvent(event);
  };

  const sharePostFailureCallBackFunction = (response: any) => {
    shareKeyAPICount.current = shareKeyAPICount.current + 1;
    shareKeyAPICount.current===shareWithUsers.length && notifySender();
    if(response === failureResponses.INVALID_TOKEN) navigate('/')
    setShowProgressBar(false)
    setUserError(response.detail);
    setProgress(0);
    setShareButtonClicked(false)
  }

  const encrypt = async (item: any, index: number, decrypted: any) => {
    const publicKey = await openpgp.readKey({ armoredKey: publicKeyList[index].key });    
    const encrypted = await openpgp.encrypt({
      message: await openpgp.createMessage({ text: decrypted }), // input as Message object
      encryptionKeys: publicKey,
    });
    postApiCall(`/projects/${projectId}/project_key/${props.keyData.keyId}/shared_key/`,
      { password: encrypted, can_share: item.privilege === "DOWNLOAD" ? false : true, user: item.id, public_key_id: publicKeyList[index].id },
      sharePostSuccessCallBackFunction,
      sharePostFailureCallBackFunction)
  }

  const handleShareSubmit = async () => {
    if (handleErrorValidation()) {
      setShareButtonClicked(true)
      try {
        setShowProgressBar(true)
        const passphrase = passwordValue;
        const privateKeyNew = await openpgp.readPrivateKey({ armoredKey: privateKey });
        let privateKeyGP;
        if (privateKeyNew.isDecrypted()) {
          privateKeyGP = privateKeyNew;

        } else {
          privateKeyGP = await openpgp.decryptKey({
            privateKey: await openpgp.readPrivateKey({ armoredKey: privateKey }),
            passphrase
          });
        }
        const message = await openpgp.readMessage({
          armoredMessage: encryptedKey // parse armored message
        });
        const { data: decrypted, signatures } = await openpgp.decrypt({
          message,
          decryptionKeys: privateKeyGP
        });
        shareWithUsers.forEach((item: any, index: number) => {
          encrypt(item, index, decrypted);
        })
        const progressRate = 100 / (shareWithUsers.length * 100);
        for (let progress = 0; progress < 100; progress += progressRate) {
          await new Promise((resolve) => setTimeout(resolve, 3));
          setProgress(progress);
        }
      }
      catch(e: any) {
        if(e.message === 'Error decrypting private key: Incorrect key passphrase') {
          setErrorValidation((prev) =>({...prev, passwordValue:'Incorrect password'}))
        }
        else {
          setErrorValidation((prev) => ({
            ...prev, privateKey: mappedKeyError? 
              'Unable to map the key due to a mismatch or incorrect private key. Verify the private key and try again.' : 'Incorrect key entered'
          }))
        }
        // props.handleModalClose();
        setProgress(0);
        setShareButtonClicked(false)
        setShowProgressBar(false)
      }
    }
  }

  React.useEffect(() => {
    if(props.keyData.keyId !== -1) {
      getApiCall(`/projects/${props.projectId}/project_key/${props.keyData.keyId}/keydata/`,
      shareSuccessCallBack,
      shareFailureCallBack
    )
    }
    // eslint-disable-next-line
  }, [props.keyData.keyId]);

  const passwordFieldCheck = async (privateKeyValue: string) => {
    setPrivateKey(privateKeyValue)
    const key = await openpgp.readPrivateKey({ armoredKey: privateKeyValue});
    if (!key.isDecrypted()) {
      setPasswordFieldShow(true);
    }
  };

  const handlePrivateKeyEnter = (event: any) => {
    setErrorValidation((prev) => ({
      ...prev,
      privateKey: ''
    }));
    passwordFieldCheck(event.target.value);
  }

  const { hideParentTooltip, handleChildTooltipMouseOver, handleChildTooltipMouseOut } = useTooltipState();

  const messagePass = () => {
    props.handleKeyExtensionClick('share');
    let event = new CustomEvent('now open modal')
    document.dispatchEvent(event)
  };

  // API call to share list of users to send mail 
  const notifySender = () => {
    postApiCall(`/projects/${projectId}/project_key/${props.keyData.keyId}/notify-sender/`,
      { shared_users_ids:sharedUserList.current },
      notifySenderSuccessCallBackFunction,
      notifySenderFailureCallBackFunction)
  };

  const notifySenderSuccessCallBackFunction = () => {
    handleSharePopupClose();
    props.keyToolListData(true);
    sharedUserList.current = [];
    shareKeyAPICount.current = 0;
  }

  const notifySenderFailureCallBackFunction = (response: any) => {
    handleSharePopupClose();
    props.keyToolListData(true);
    sharedUserList.current = [];
    shareKeyAPICount.current = 0;
    if(response === failureResponses.INVALID_TOKEN) navigate('/')
    else {
      toast.error('Unable to send notification email', {position: toast.POSITION.TOP_RIGHT})
    }
  };

  const handleSharePopupClose = () => {
    props.handleModalClose()
    setErrorValidation({privateKey: "", users: "", passwordValue: ""} )
    setPasswordFieldShow(false);
    setShareWithUsers([]);
    setPasswordValue("");
    setPrivateKey("");
    setShowProgressBar(false);
    setProgress(0);
    setShareButtonClicked(false);
  }

  const setRef = useCallback((node: HTMLParagraphElement | null) => {
    if (node) {
      keyMap.current = node;
      let event = new CustomEvent('Public key autofill');
      document.dispatchEvent(event);
    }
  }, []);

  window.addEventListener('message', function (event: any) {
    if(allowedOrigins.includes(event.origin)) {
      if (event.data.type === 'PUBLIC_KEY_MAPPING_HIT') {
        passwordFieldCheck(event.data.privateKey);
        setMappedKeyError(true);
      }
    }
  });

  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{...style}}>
        <div className={styles.popupHeaderContainer}>
        <p ref={extensionKeyRef} id='extension-storage' className={styles.extension}></p>
        <p ref={setRef} id='extension-mapping' className={styles.extension}>{props.keyData.public_key.name}</p>        
        <Grid item md={12} className={styles.popupHeader}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className={styles.modalTitle}>
            Share Key
          </Typography>
          {props.keyData.public_key.name && (
            <Tooltip title={!hideParentTooltip && props.keyData.public_key.name?.length>50 && props.keyData.public_key.name} placement="top">
              <Chip
                label={
                  <Box className={styles.chipDeletedIcon}>
                    {
                      props.keyData.public_key.is_deleted && 
                      <Tooltip 
                        title={'The key has been deleted from the system'} 
                        placement="top"
                        onMouseEnter={handleChildTooltipMouseOver}
                        onMouseLeave={handleChildTooltipMouseOut}  
                      >                        
                        <img src={DeletedKeyIcon} alt="Deleted key icon"/>
                      </Tooltip>
                    }
                    {truncate(props.keyData.public_key.name, { length: 50 })}
                  </Box>
                }
                variant="filled"
                size="small"
                className={`${styles.keyChipDesign} ${styles.sharedKey}`}
              />
            </Tooltip>
            )}      
        </Grid>
        <div className={styles.extensionButton} onClick={messagePass}>
          <img src={extensionIcon} alt="" />
          <p>key extension</p>
        </div>
        </div>
        <Grid item md={12} className={styles.addKeyMessage}>
          <Typography>
            To securely share the file with the recipient, please provide your private key. The private key is essential for sharing the file and ensuring its confidentiality. By entering your private key, you are granting access to the encrypted file.
          </Typography>
        </Grid>
        <Grid item md={12} className={styles.addKeyFields}>
          <TextField
            id="outlined-password-input"
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            label="Enter the private key to share"
            autoComplete="off"
            value={privateKey}
            onChange={(event)=>{
              handlePrivateKeyEnter(event);
              setMappedKeyError(false);
            }}
            error={errorValidation.privateKey !== ""}
            helperText={errorValidation.privateKey}
          />
        </Grid>

        {passwordField &&
          <Grid item md={12} className={styles.addKeyFields}>
            <TextField
              id="outlined-password-input"
              type="password"
              fullWidth
              label="Enter your key password"
              autoComplete="off"
              value={passwordValue}
              onChange={(event) => {
                setPasswordValue(event.target.value)
                setErrorValidation((prev) => ({...prev, passwordValue: ''}))
              }}
              error={errorValidation.passwordValue !== ""}
              helperText={errorValidation.passwordValue}
               />
          </Grid>}
        <ShareKey setShareWithUsers={handleShareWithUsers}
          shareWithUsers={shareWithUsers}
          handleSharedUserPrivilegeChange={handleSharedUserPrivilegeChange}
          setPublicKeyList={setPublicKeyList}
          userError={userError}
          keyId={props.keyData.keyId}
          errorValidation={errorValidation}
          handleModalClose={props.handleModalClose}
          setErrorValidation={setErrorValidation}
        />
        {
          showProgressBar && <Grid item md={12}>
            <LinearProgressWithLabel value={progress} />
          </Grid>
        }
        <Grid container justifyContent="center" className={stylesProjectList.buttons}>
          <Button variant="outlined"
            className={stylesProjectList.cancelButton} onClick={handleSharePopupClose}
          >
            Cancel
          </Button>
          {
            ((!shareButtonClicked) || shareWithUsers.length > 0) &&
            <Button variant="contained" className={stylesProjectList.submitButton} sx={{ minWidth: "100px" }} onClick={handleShareSubmit}>
              Share
            </Button>
          }
        </Grid>
      </Box>
    </Modal>
  )
}

export default ShareKeyModal;
import styles from '../AddKey/AddKey.module.scss';
import React from "react";
import { Grid, Autocomplete, Box, Typography, Menu, MenuItem, TextField, Button, FormControl, Select, Divider, Checkbox } from '@mui/material';
import { getApiCall } from '../../../utils/Utils';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import truncate from 'lodash/truncate'
import { failureResponses } from '../../../constants/constants';

const ShareKey = (props: any) => {

  interface Item {
    id: number;
    name: string;
  };

  interface User {
    userList: Item[],
    dropdownList: Item[]
  }

  const params = useParams();
  const navigate = useNavigate();
  const projectId = params.projectId;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [users, setUsers] = React.useState<User>({userList:[], dropdownList:[]})
  const isMenuOpen = Boolean(menuAnchorEl);
  const [selectedUsers, setSelectedUsers] = React.useState<any>([]);
  const [addButtonClicked, setAddButtonClicked] = React.useState(false);
  const [shareOption, setShareOption] = React.useState("DOWNLOAD");
  const [shareSelectedUsers, setShareSelectedUsers] = React.useState<any>([]);
  const [showPlaceholder, setShowPlaceholder] = React.useState(true);
  const [userSelectListTemp, setUserSelectListTemp] = React.useState<any[]>([])
  const [removeAllChecked, setRemoveAllChecked] = React.useState(false)
  const [removeUserItem, setRemoveUserItem] = React.useState<any[]>([])

  const handleDownloadMenuOpen = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleDownloadMenuItemClick = (event: any) => {
    setShareOption(event.currentTarget.getAttribute('value'));
    setMenuAnchorEl(null);
  };
  const publicKeySuccessCallBack = (response: any) => {
    props.setPublicKeyList(response)
  }
  const publicKeyFailureCallBack = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
    else toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
    props.handleModalClose()
  }
  const getPublicKeyList = () => {
    if (shareSelectedUsers.length !== 0) {
      const users = shareSelectedUsers.map((item: any) => item.id)
      getApiCall(`/projects/${projectId}/user/public_keys/?user_ids=${users}`,
        publicKeySuccessCallBack,
        publicKeyFailureCallBack)
    }
  }

  const successCallBackFunction = (response: any) => {
    if(response.length > 1) {
      const updatedRespose = [{id: -1, name: 'All'}, ...response]
      setUsers({userList: updatedRespose, dropdownList: updatedRespose});
    }
    else setUsers({userList: response, dropdownList: response});
  };

  const failureCallBackFunction = (response: any) => {
    if (response === failureResponses.INVALID_TOKEN) navigate("/");
    else toast.error("Something went wrong. Try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const getUsers = () => {
    getApiCall(props.keyId ? `/projects/${projectId}/public_keys/users/?project_key_id=${props.keyId}` : `/projects/${projectId}/public_keys/users/`,
      successCallBackFunction, failureCallBackFunction)
  }

  const handleRemoveSelectedUsers = () => {
    setSelectedUsers([]);
    if (removeAllChecked) {
      props.setShareWithUsers([]);
      setShareSelectedUsers([]);
    }
    else {
      const temp = props.shareWithUsers.filter((item: any) => !removeUserItem.some((entry: any) => entry === item.id));
      props.setShareWithUsers(temp);
      const selectedTemp = shareSelectedUsers.filter((item: any) => item.id !== -1 && !removeUserItem.some((entry: any) => entry === item.id));  
      setShareSelectedUsers(selectedTemp);    
    }
    setRemoveUserItem([]);
    setRemoveAllChecked(false)
  }

  const getOptionsForUserSelectListTemp = (newValue: any, action: any) => {
    if (action === "selectOption") {
      if (newValue.length > selectedUsers.length) {
        if (newValue[newValue.length-1]?.name === 'All') setUserSelectListTemp(users.dropdownList.filter(item => item.name !== 'All').map((user: any)=>user.name));
        else {
          const lastSelected = newValue.find((option: any) => !selectedUsers.includes(option));
          setUserSelectListTemp((prev) => [...prev, lastSelected.name]);
      }
      }
    }
    else {
      const removed = selectedUsers.filter((option: any) => !newValue.includes(option));
      if (removed.length > 0) {
       
        const lastRemoved = removed[removed.length - 1];
        if(lastRemoved?.name === 'All') setUserSelectListTemp([])
        else{
          const tempList = userSelectListTemp.filter((item) => item !== lastRemoved.name)
          setUserSelectListTemp(tempList)
        }
      }
    }
    if (newValue[newValue.length-1]?.name === 'All') setSelectedUsers(users.userList);
    else {
      const removed = selectedUsers.filter((option: any) => !newValue.includes(option));
      const lastRemoved = removed[removed.length - 1];
      if(lastRemoved?.name === 'All') setSelectedUsers([])
      else setSelectedUsers(newValue.filter((item: any) => item.name !== 'All'));}
  };

  const dropdownUserList = () => {
    const newUserList: any[] = [];
    users.userList.forEach((item: any) => {
      if (!shareSelectedUsers.some((user: { id: any; }) => user.id === item.id)) 
        newUserList.push(item)
    });
    setUsers({...users, dropdownList: newUserList.length === 1 ? [] : newUserList})
  };

  React.useEffect(() => {
    const temp: any[] = [];
    selectedUsers.filter((item: any)=>item.name !== 'All').forEach((item: any) => {
      if (!props.shareWithUsers.some((user: { id: any; }) => user.id === item.id)) 
        temp.push({ id: item.id, name: item.name, privilege: shareOption })
    })
    
    props.setShareWithUsers([...props.shareWithUsers, ...temp])
    setShareSelectedUsers([...props.shareWithUsers, ...temp])
    // eslint-disable-next-line
  }, [addButtonClicked])

  React.useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    getPublicKeyList();
    dropdownUserList();
    // eslint-disable-next-line
  }, [shareSelectedUsers])

  return (
    <>
      <Grid item md={12} className={styles.fileFlex}>
        <Grid item md={8} className={styles.addKeyFields}>
          <Autocomplete
            sx={{ width: "565px" }}
            multiple
            disableCloseOnSelect
            disableClearable
            id="multiple-limit-tags"
            value={selectedUsers}
            options={users.dropdownList}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <span onClick={(e) => e.preventDefault()}>
                  <Checkbox
                    key={option.id}
                    sx={{
                      color: '#3A35418A',
                      padding: "0 4px 0 0",
                      '&.Mui-checked': {
                        color: '#151515',
                      },
                    }}
                    checked={selected}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setUserSelectListTemp((prev) => [...prev, option.name])
                      }
                      else {
                        const tempList = userSelectListTemp.filter((item) => item !== option.name)
                        setUserSelectListTemp(tempList)
                      }
                    }}
                  />
                </span>
                {option.name}
              </li>
            )}
            onChange={(event, value, action) => {
              setShowPlaceholder(false)
              getOptionsForUserSelectListTemp(value, action)
              props.setErrorValidation((prev: any) => ({...prev, users: ''}))
            }}
            renderTags={(value, getTagProps) =>
              <>
                {!showPlaceholder && userSelectListTemp.map((option, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                  {index < 3 && <span {...getTagProps({ index })}>{`${userSelectListTemp.length > 2 ? truncate(option, { length: 12 }) : option}${userSelectListTemp.length - 1 !== index ? ',' : ''} `}</span>}
                  {index === userSelectListTemp.length - 1 && (
                    <div>
                      {index >= 3 && `+${userSelectListTemp.length-3} more`}
                      <Grid className={styles.downloadButton} onClick={handleDownloadMenuOpen}>
                        <Typography>{shareOption === "DOWNLOAD" ? "Download" : "Download & Share"}</Typography>
                      </Grid>
                    </div>
                  )}
                </Box>
                ))}
                <Menu
                  anchorEl={menuAnchorEl}
                  open={isMenuOpen}
                  onClose={() => setMenuAnchorEl(null)}
                >
                  <MenuItem onClick={handleDownloadMenuItemClick} value="DOWNLOAD">Download</MenuItem>
                  <MenuItem onClick={handleDownloadMenuItemClick} value="DOWNLOAD_SHARE">Download & Share</MenuItem>
                </Menu>
              </>}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={showPlaceholder ? "Select user name" : ""}
                error={props.keyId && props.errorValidation.users !== ""}
                helperText={props.keyId && props.errorValidation.users}
              />
            )}

          />
        </Grid>
        <Grid item md={3}>
          <Button variant="outlined"
            className={userSelectListTemp.length !== 0 ? styles.expandButtonActive : styles.expandAddButton}
            size="large"
            onClick={() => {
              setShowPlaceholder(true)
              setUserSelectListTemp([])
              if (userSelectListTemp.length !== 0) {
                setAddButtonClicked(!addButtonClicked)
              }
            }
            }>
            Add
          </Button>
        </Grid>
      </Grid>
      {
        props.shareWithUsers.length !== 0 &&
        <Box className={styles.removeButtonContainer}>
          <Box className={styles.removeAllBox}>
            <Checkbox
              checked={removeAllChecked}
              onChange={(event) => {
                setRemoveAllChecked(!removeAllChecked)
                if (event.target.checked) {
                  props.shareWithUsers.forEach((item: any) => {
                    setRemoveUserItem((prev) => [...prev, item.id])
                  })
                }
                else setRemoveUserItem([])
              }}
              sx={{
                color: '#3A35418A',
                padding: 0,
                '&.Mui-checked': {
                  color: '#151515',
                },
              }}
            />
            <Button variant="contained" className={removeUserItem.length>0 ? styles.removeButton : styles.removeButtonDisabled} onClick={handleRemoveSelectedUsers}>
              {removeAllChecked || removeUserItem.length === shareSelectedUsers.length ? 'REMOVE ALL' : 'REMOVE'}
            </Button>
          </Box>
          <Divider />
        </Box>
      }
      <Box className={styles.sharedUsersList}>

        {
          props.shareWithUsers.map((item: any, index: number) =>
            <Box className={styles.sharedUser} key={item.id}>
              <div className={styles.shareUserCheckBox}>
                <Checkbox
                  checked={removeAllChecked || removeUserItem.some((entry) => entry === item.id)}
                  sx={{
                    color: '#3A35418A',
                    padding: 0,
                    '&.Mui-checked': {
                      color: '#151515',
                    },
                  }}
                  onChange={(event) => {
                    if (event.target.checked) {
                      const removeUsers = [...removeUserItem, item.id];
                      removeUsers.length === shareSelectedUsers.length && setRemoveAllChecked(true);
                      setRemoveUserItem(removeUsers);
                    }
                    else {
                      const temp = removeUserItem.filter((value) => value !== item.id)
                      setRemoveUserItem(temp)
                      setRemoveAllChecked(false)
                    }
                  }}
                />
                <Typography>{item.name}</Typography>
              </div>
              <FormControl variant="standard"
                size="small"
              >
                <Select
                  value={item.privilege}
                  onChange={(event) => props.handleSharedUserPrivilegeChange(event, item)}
                  id="demo-simple-select-standard"
                  disableUnderline
                >
                  <MenuItem value="DOWNLOAD">Download</MenuItem>
                  <MenuItem value="DOWNLOAD_SHARE">Download & Share</MenuItem>
                </Select>
              </FormControl>
            </Box>)
        }
      </Box>
    </>
  )

}

export default ShareKey;